<template>
  <div class="main">
    <!-- 头部步骤 -->
    <!-- 分类选择 -->
    <a-row>
      <a-col :span="8">
        <div class="categoryList">
            <ul>
              <li
                v-for="(item, index) in categoryList"
                :key="item.id"
                :class="{active:selectLever1===index}">
                <a href="javascript:;"
                  @click="selectCategory(item,index)"
                  >
                  {{item.name}}</a></li>
            </ul>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="categoryList">
          <ul>
              <template v-if="categoryList!==[] && selectLever1!==''">
                  <li
                  v-for="(item,index) in  categoryList[this.selectLever1].children"
                  :key="item.id"
                  :class="{active:selectLever2===index}">
                  <a href="javascript:;"
                    @click="selectCategory(item,index)"
                    >
                    {{item.name}}</a>
                  </li>
              </template>

              </ul>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="categoryList">
            <ul>
              <template v-if="categoryList!==[] && selectLever1!=='' && selectLever2!==''">
                  <li
                  v-for="(item,index) in  categoryList[this.selectLever1].children[this.selectLever2].children"
                  :key="item.id"
                  :class="{active:selectLever3===index}">
                  <a href="javascript:;"
                    @click="selectCategory(item,index)"
                    >
                    {{item.name}}</a>
                  </li>
              </template>

              </ul>
        </div>
      </a-col>
    </a-row>
    <!-- 当前选择商品类别 -->
    <div class="selectTips">您当前选择的商品类别是：
      {{this.selectLever1>=0 && this.selectLever1!==''
      ? this.categoryList[this.selectLever1].name
      :''}}
       {{this.selectLever2>=0 && this.selectLever2!==''
      ? '>>' + this.categoryList[this.selectLever1].children[this.selectLever2].name
      :''}}
       {{this.selectLever3>=0 && this.selectLever3!==''
      ? '>>' +this.categoryList[this.selectLever1].children[this.selectLever2].children[this.selectLever3].name
      :''}}
      </div>
    <div style="display:flex; justify-content: center; margin-top:30px;">
      <a-button @click="nextStep" type="primary">
        确认
      </a-button>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      idGoyGoods: '',
      active: 'active',
      selectLever1: '',
      selectLever2: '',
      selectLever3: '',
      categoryList: []
    }
  },
  computed: {
  },
  created () {
    this.goodsGoyGoodsList()
  },
  methods: {
    nextStep () {
      console.log(111111)
      if (this.selectLever1 !== '' && this.selectLever2 !== '' && this.selectLever3 !== '') {
        this.$router.replace({
          path: '/goods/addOrEdit',
          query: {
            // selectLever1: this.selectLever1,
            // selectLever2: this.selectLever2,
            // selectLever3: this.selectLever3
            idGoyGoods: this.idGoyGoods
          }
        })
        this.$emit('idGoyGoodsChange', this.idGoyGoods)
      } else {
        this.$store.dispatch('showToast', {
          type: 'warning',
          message: '请选择三级分类'
        })
      }
    },
    selectCategory (item, index) { // 选择类别
      console.log(item.level, index, 'itemindex')
      if (item.level === 1 && this.selectLever1 !== index) {
        this.selectLever1 = ''
        this.selectLever2 = ''
        this.selectLever3 = ''
        this.selectLever1 = index
      }
      if (item.level === 2) {
        this.selectLever2 = index
      }
      if (item.level === 3) {
        this.selectLever3 = index
        this.idGoyGoods = item.id
        console.log(item)
      }
    },
    // 加载列表
    async goodsGoyGoodsList (callback = () => { }) {
      const res = await this.$store.dispatch('http', {
        api: 'goodsGoyGoodsList',
        complete: () => {
          callback()
        }
      })
      this.categoryList = res
      console.log(res)
    }
  }
}
</script>

<style lang="scss" scoped>
.buzhou{
  margin-top: 10px;
  display: flex;
  border-radius: 5px;
  overflow:hidden;
  .buzhou-item{
    display: flex;
    justify-content:flex-start;
    flex: 1;
    background:#efefef;
    height:88px;
    .buzhouMess{
      padding-right: 20px;
      div:first-child{
        margin-top:10px;
        font-size: 22px;
      }
      div:last-child{
        font-size: 10px;
      }
    }
    .num{
      color: #ffffff;
      flex-grow: 0;
      flex-shrink: 0;
      width:55px;
      height:45px;
      div{
        margin-top:17px;
        margin-left: 20px;
        width:20px;
        height: 20px;
        border-radius: 50%;
        background:#74797d;
        display:flex;
        justify-content: center;
      }
    }
  }
  .active{
    background:#108fd2;
    color: #ffffff;
  }
}
.categoryList{
  // margin: 50px 10px 0 10px;
  background:#f2f2f2;
  border: 1px solid #e6e6e6;
  height:350px;
  overflow:hidden;
  &:hover{
    overflow-y:scroll;
  }
  ul{
    padding:10px;
    li{
      padding:0 10px;
      height:34px;
      display:flex;
      align-items: center;
       a{
         display:block;
         width: 100%;
         color:#333333;
       }
    }
    li.active{
      a{
        color: #0b76ac;
      }
      background:#b8e0f1;
    }
  }
}
.selectTips{
  background:#fdf8e4;
  padding:10px 20px;
  margin-top: 30px;
  color:#b19969;
  border:1px solid #f0ebda;
}
</style>
