<template>
  <div class="main">
    <!-- {{businessTypeList}}---{{form.businessTypeCode}} -->
    <!-- 头部步骤 -->
    <!-- <div>{{businessTypeList}}</div> -->
    <Step @preStep="()=>{ stepShow=2}" @nextStep="toNextStep" :activeIndex="stepShow"></Step>
    <span v-show="0===1">{{form}}</span>
    <!-- 表单信息 -->
    <a-form-model class="formModal" ref="goodForm" :rules="rules" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <div v-show="stepShow===2" class="detailContent">
        <div :style="{display:programName==='tea'?'none':'block'}">
          <div class="baseInfo">商品类型</div>
            {{businessPropertyType}}
            <div class="businessTypeList">
              <div @click="changeBusinessType(item)" :class="item.businessTypeCode === form.businessTypeCode?'active':''" v-for="(item,index) in businessTypeList" :key="index">
                <span>{{item.businessTypeName}}</span>
                <span>({{item.memo}})</span>
              </div>
            </div>
        </div>
        <div class="baseInfo">商品基本信息</div>
        <a-form-model-item label="商品分类" prop="idGoyGoods">
          {{categoryLeverListValue}}
          <a-button @click="selectCategory" :disabled="isEditorAdd==='edit'" style="margin-left:20px;">选择商品分类</a-button>
        </a-form-model-item>
        <a-form-model-item prop="name" label="商品名称">
          <a-input v-model="form.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item prop="" label="商品品牌">
            <a-select
                  show-search
                  :default-value="form.idGoBrand"
                  :key="form.idGoBrand"
                  placeholder="请选择品牌"
                  option-filter-prop="children"
                  @change="handleChangePingpai"
                >
                <a-select-option v-for="d in grandData" :key="d.id" :value="d.id">
                  {{d.name}}
                </a-select-option>
              </a-select>
        </a-form-model-item>
        <a-form-model-item label="商品简介">
          <a-input v-model="form.intro" type="textarea" placeholder="请输入" />
          <div slot="extra">商品卖点可填写商品的简介或特点，最长不能超过140个汉字</div>
        </a-form-model-item>
        <!--<a-form-model-item label="分享描述">
          <a-input v-model="form.shareIntro" placeholder="请输入" />
        </a-form-model-item>-->
        <a-form-model-item prop="imgUrls" label="商品图">
            <ImageManage v-model="form.imgUrls" @change="$refs.goodForm.validateField(['imgUrls'])" :imageLength="15" :square="false" :size="1024*1024*2" :showWidth="true">
                <div slot="extra">建议大小不超过200KB，建议尺寸：800*800像素，仅支持jpg、png格式，最多上传15张</div>
            </ImageManage>
            <!-- <div class="drag__ct">
                <draggable
                    class="dragImgList"
                    v-model="form.imgUrls"
                    handle=".item"
                    chosenClass="chosen"
                    forceFallback="true"
                    group="people"
                    animation="1000"
                    @start="onStart"
                    @end="onEnd"
                    >
                    <div class="item" v-for="(item, index) in form.imgUrls" :key="index">
                        <viewer>
                        <img  :src="item" />
                        </viewer>
                        <a-icon class="del" @click="form.imgUrls.splice(index,1); $refs.goodForm.validateField(['imgUrls'])"  style="color:white;" type="delete" />
                    </div>
                </draggable>
                <a-upload
                    list-type="picture-card"
                    :show-upload-list="false"
                    @preview="handlePreview"
                    :action="$consts.uploadFileUrl"
                    :headers="$store.state.header"
                    :before-upload="beforeUpload"
                    @change="handleChangeMainImgList($event)"
                    >
                    <div v-if="fileList.length < 15">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">
                        Upload
                        </div>
                    </div>
                    </a-upload>
            </div>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
            <div slot="extra">建议大小不超过200KB，建议尺寸：800*800像素，仅支持jpg、png格式，最多上传15张</div> -->
        </a-form-model-item>
       <a-form-model-item prop=" " label="商品视频">
         <video v-if="showVideo" width="320" height="240" style="margin-right:10px;" controls>
           <source :src="form.videoUrl" type="video/mp4">
           &lt;!&ndash; <source :src="movie.ogg" type="video/ogg"> &ndash;&gt;
           您的浏览器不支持 HTML5 video 标签。
         </video>
         <a-upload
           style="width:50px;height:50px; margin-bottom:53px;"
           :file-list="videoList"
           :action="$consts.uploadFileUrl"
           :headers="$store.state.header"
           @change="handleMvChange($event)"
           list-type="picture-card"
         >
           <div v-if="form.videoUrl===''">
               <a-icon type="plus" />
               <div class="ant-upload-text">
                 Upload
               </div>
             </div>
         </a-upload>
        <div slot="extra">添加主图视频可提升商品的成交转化，有利于获取更多流量，建议时长 9-30 秒、视频宽高和商品图一致。</div>
       </a-form-model-item>
        <a-form-model-item prop="" label="商品编码">
          <a-input v-model="form.goodsNo" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item prop="" label="商品条码">
          <a-input v-model="form.goodsBarcode" placeholder="请输入" />
          <div slot="extra">用于快速识别商品所标记的唯一编码，比如：69开头的13位标准码。</div>
        </a-form-model-item>
        <!-- <a-form-model-item v-if="form.goodsType===2" label=" " :colon="colonBool">
              <a-form-model-item prop="ciq" label="进口商品ciq码">
                        <a-input style="width:300px" v-model="form.ciq" placeholder="请输入" />
              </a-form-model-item>
              <a-form-model-item prop = "shuiLu" label="税率">
                <a-input-number
                  v-model="dutyRate"
                  :default-value="0"
                  :min="0"
                  :max="100"
                  style="width:300px;"
                  @change="onChangeDutyRate"
                />%
              </a-form-model-item>
              <div slot="extra">进口商品需要填定CIQ码和税率，税率默认为0，请注意设置销售价格是含税价格。</div>
        </a-form-model-item> -->
        <!-- 商品规格属性及图片 -->
        <div class="baseInfo">商品规格属性及图片</div>
        <a-form-model-item label="商品规格">
                <a-form-model-item label="规格值" labelAlign="left">
                  规格值（通过选择规格值建立商品与商品规格的关系）
                </a-form-model-item>
        </a-form-model-item>
        <a-form-model-item v-if="selectedSpecList.length>0" label=" " :colon="colonBool" prop="type">
              <a-form-model-item  :colon="colonBool">
                <div class="guige" v-for="(item,index) in selectedSpecList" :key="index">
                  <div class="guigeLabel">
                      <span style="font-weight: bold;">{{item.name}}</span><br/>
                      <a-button @click="addGuigeVal(item)" style="width:150px; margin-right:10%;">+编辑规格值</a-button>
                  </div>
                  <div class="guigeContent">
                      <span  v-for="(it,idx) in item.item" :key="idx">
                          <a-checkbox  :checked="it.checked"  @change="value => changeGuige(value,index,idx)">
                            {{it.name}}
                          </a-checkbox>
                          <a-icon style="color:#aaaaaa"  @click="delItem({str:'guige',goySpec:item,item:it})" v-if="it.tenantId!=='0'" type="minus-circle" />
                      </span>
                  </div>
                </div>
              </a-form-model-item>
        </a-form-model-item>
        <!-- 添加商品规格 -->

        <a-form-model-item label=" " :colon="colonBool">
              <a-form-model-item  :colon="colonBool">
                <a-button @click="showGoodModal()" type="primary" style="width:150px; margin-right:10%;">+添加商品规格</a-button>
              </a-form-model-item>
              <div slot="extra" style="line-height:20px;">可勾选商品对应的规格及规格值，规格及规格值名称都可以自定义；当勾选两种不同规格的规格值后将组合成一种商品SKU，可从下方表格中进行具体设定。
  规格可自定义添加，但最多不超过3组；每组规格值也可自定义添加，但最多不超过10个。
  自定义的规格值是可以删除的</div>
        </a-form-model-item>
        <a-form-model-item v-if="!isSingleSpec"  label="商品价格及库存">
          <a-table :rowKey="(record, index) => index" :pagination="1===0" :columns="columns" :data-source="form.goProductList">
            <span slot="customTitle">销售价<span style="color:red;"> *</span></span>
            <div style="width:300px;" slot="nameList" slot-scope="text">
              <span v-if="text">
                <span v-if="text.length">
                  <!-- 长度大于=2为数组 -->
                  <span v-for="(item,index) in text" :key="index">
                    {{item.name}}
                  </span>
                </span>
                <span v-else>
                  <!-- 长度为1时是对像 -->
                  {{text.name}}
                </span>
              </span>

            </div>
            <span slot="price"  slot-scope="text,record">
              <a-input-number defaultValue="" style="width:100px;" @change="(e)=>{changTableVal(e,record,'price')}" v-model="record.price" placeholder="请输入" />
            </span>
            <span slot="stock" slot-scope="text,record">
              <a-input-number @change="(e)=>{changTableVal(e,record,'stock')}"  style="width:100px;" v-model="record.stock" placeholder="请输入" />
            </span>
            <span slot="weight" slot-scope="text,record">
              <a-input-number @change="(e)=>{changTableVal(e,record,'weight')}"  style="width:100px;" v-model="record.weight" placeholder="请输入" />
            </span>
            <span slot="volume" slot-scope="text,record">
              <a-input-number @change="(e)=>{changTableVal(e,record,'volume')}" style="width:100px;" v-model="record.volume" placeholder="请输入" />
            </span>
            <span slot="marketPrice"  slot-scope="text,record">
              <a-input-number @change="(e)=>{changTableVal(e,record,'marketPrice')}" style="width:100px;"  v-model="record.marketPrice" placeholder="请输入" />
            </span>
            <span slot="costPrice"  slot-scope="text,record">
              <a-input-number @change="(e)=>{changTableVal(e,record,'costPrice')}" style="width:100px;"  v-model="record.costPrice" placeholder="请输入" />
            </span>
            <span slot="productBarcode" slot-scope="text,record">
              <a-input style="width:100px;"  @change="(e)=>{changTableVal(e,record,'productBarcode')}" v-model="record.productBarcode" placeholder="请输入" />
            </span>
            <span slot="productNo" slot-scope="text,record">
              <a-input style="width:100px;"  @change="(e)=>{changTableVal(e,record,'productNo')}" v-model="record.productNo" placeholder="请输入" />
            </span>
            <span slot="outId" slot-scope="text,record">
              <a-input style="width:100px;"  @change="(e)=>{changTableVal(e,record,'outId')}" v-model="record.outId" placeholder="请输入" />
            </span>
            <span slot="expressDay" slot-scope="text,record">
              <a-input style="width:100px;"  @change="(e)=>{changTableVal(e,record,'expressDay')}" v-model="record.expressDay" placeholder="请输入" />
            </span>
          </a-table>
          <a-form-model-item prop='goProductList'></a-form-model-item>
        </a-form-model-item>
        <a-form-model-item v-if="!isSingleSpec"  prop="productList111"  label="商品规格图片（多张）">
          <div>
            <a-checkbox :checked="imgListSwitchChecked" @change="()=>{ imgListSwitchChecked=!imgListSwitchChecked; $refs.tableBlock.style.height = $refs.tableBlock.style.height === 'auto' ? '0' : 'auto' }">
              建议大小不超过200KB，建议尺寸：800*800像素，仅支持jpg、png格式，最多上传15张
            </a-checkbox>
          </div>
          <div ref="tableBlock" style="height:0px;overflow:hidden;">
            <a-table :rowKey="(record, index) => index" :pagination="false" :columns="columnsImg" :data-source="form.goProductList">
              <span style="width:300px;" slot="nameList" slot-scope="text">
                <span v-if="text">
                  <span v-if="text.length">
                    <!-- 长度大于=2为数组 -->
                    <span v-for="(item,index) in text" :key="index">
                      {{item.name}}
                    </span>
                  </span>
                  <span v-else>
                    <!-- 长度为1时是对像 -->
                    {{text.name}}
                  </span>
                </span>
              </span>
              <span slot="imgList" slot-scope="text, record, index">
                <ImageManage v-model="form.goProductList[index].imgUrls" :imageLength="15" :square="false" :size="1024*1024*2" :showWidth="true">
                    <div slot="extra">建议大小不超过200KB，建议尺寸：800*800像素，仅支持jpg、png格式，最多上传15张</div>
                </ImageManage>
                <!-- {{form.goProductList[index].imgUrls}} -->
                    <!-- <div class="drag__ct">
                        <draggable
                            class="dragImgList"
                            v-model="form.goProductList[index].imgUrls"
                            handle=".item"
                            chosenClass="chosen"
                            forceFallback="true"
                            group="people"
                            animation="1000"
                            @start="onStart"
                            @end="onEnd"
                            >
                            <div class="item" v-for="(item, index2) in form.goProductList[index].imgUrls" :key="index2">
                                <viewer>
                                    <img  :src="item" />
                                </viewer>
                                <a-icon class="del" @click="form.goProductList[index].imgUrls.splice(index2,1)"  style="color:white;" type="delete" />
                            </div>
                        </draggable>
                        <a-upload
                            name="file"
                            list-type="picture-card"
                            style="width: 100%;height: auto;"
                            :show-upload-list="false"
                            :action="$consts.uploadFileUrl"
                            :disabled="disabledStatus"
                            :headers="$store.state.header"
                            :before-upload="value => beforeUpload(value,form.goProductList[index])"
                            @change="value => handleChange(value,form.goProductList[index])"
                            @preview="handlePreview"
                        >
                            <div v-if="!text || text.length < 15">
                                <div class="ant-upload-text">
                                    上传
                                </div>
                            </div>
                        </a-upload>
                    </div> -->
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img
                  alt="example"
                  style="width: 100%"
                  :src="previewImage"
                />
              </a-modal>
              </span>
            </a-table>
          </div>
        </a-form-model-item>
        <a-form-model-item v-if="isSingleSpec"  label="规格描述">
          <a-input v-model="form.goProductList[0].specification" style="width:100%;" placeholder="请输入规格说明" /><br/>
        </a-form-model-item>
        <a-form-model-item v-if="isSingleSpec" label="价格">
          销售价 （元）<a-input-number v-model="form.goProductList[0].price" style="width:100px;" /><br/>
          市场价 （元）<a-input-number v-model="form.goProductList[0].marketPrice" style="width:100px;" /><br/>
          成本价 （元）<a-input-number v-model="form.goProductList[0].costPrice" style="width:100px;" />
        </a-form-model-item>
        <a-form-model-item v-if="isSingleSpec" label="重量体积">
          重量 （kg）<a-input-number v-model="form.goProductList[0].weight" style="width:100px;" /><br/>
          体积 （m³）<a-input-number v-model="form.goProductList[0].volume" style="width:100px;" />
        </a-form-model-item>
        <a-form-model-item  v-if="isSingleSpec" label="关联第三方商品ID">
          <a-input v-model="form.goProductList[0].outId" style="width:100%;" /><br/>
        </a-form-model-item>
        <a-form-model-item  v-if="isSingleSpec" label="总库存">
          <a-input-number v-model="form.goProductList[0].stock" style="width:100%;" /><br/>
        </a-form-model-item>
        <a-form-model-item  v-if="isSingleSpec" label="预计发货天数">
          <a-input v-model="form.goProductList[0].expressDay" style="width:100%;" /><br/>
        </a-form-model-item>
        <!-- 属性回显 -->
        <span v-show="0>1">{{form.goodsProperties}}此处不能删除  否则多规格  属性不会显示</span>
        <a-form-model-item label="商品属性" :wrapperCol="{span:20}">
          <GoodsProperties @change="changegoodsProperties" ref=goodsProperties></GoodsProperties>
        </a-form-model-item>

        <!-- 虚拟分类 -->
        <a-form-model-item label="虚拟分类" :wrapperCol="{span:20}">
            <a-tree v-if="treeData && treeData.length > 0"
                checkable
                :tree-data="treeData"
                :defaultExpandAll="true"
                checkStrictly v-model="halfCheckedGoyIds"
                :replace-fields="replaceFields">
            </a-tree>
        </a-form-model-item>

        <div v-if="hasBusProp('delivery')" class="baseInfo">物流信息</div>
        <a-form-model-item  v-if="hasBusProp('delivery')" label="配送方式">
            <a-row>
              <a-col :span="6" v-for="(item,index) in formBusProp['delivery'].businessProperty" :key="index">
                <a-checkbox v-model="item.checked" @click="(e)=>{changeFormBusProp(e,'delivery',item)}" :value="item.propertyCode">
                  {{item.propertyName}}
                </a-checkbox>
              </a-col>
            </a-row>
        </a-form-model-item>
        <!-- <a-form-model-item  prop="expressDay"  v-if="hasBusProp('delivery')" label="预计发货">
           <a-input v-model="form.expressDay" placeholder="请输入" />
        </a-form-model-item> -->
        <div class="baseInfo">其他信息</div>
        <a-form-model-item label="开售时间" v-show="programName!=='tea'">
          <a-row>
              <a-col :span="6">
                <a-radio-group style="line-height:40px;" v-model="form.saleType">
                    <a-radio :value="0">
                      立即上架
                    </a-radio>
                    <a-radio :value="1">
                      定时开售  &emsp;<a-date-picker :disabled-date="disabledDateStart" v-model="form.gmtOnSell" :format="dateFormat" @change="()=>{}" />
                    </a-radio>
                    <a-radio :value="2">
                      放入仓库
                    </a-radio>
                  </a-radio-group>
              </a-col>
            </a-row>

        </a-form-model-item>
        <a-form-model-item label="定时下架" v-show="programName!=='tea'">
            <a-col :span="6">
                <a-radio-group style="line-height:40px;" v-model="form.taskOnSell">
                    <a-radio :value="1">
                      开启 &emsp;<a-date-picker :disabled-date="disabledDateStart" v-model="form.gmtOffline" :format="dateFormat" />
                    </a-radio>
                    <a-radio :value="0">
                      关闭
                    </a-radio>
                  </a-radio-group>
              </a-col>
              <div slot="extra" style="line-height:20px;">开启定时下架后，系统会在设置时间下架该商品。下架时间需晚于开售时间，商品才能定时开售。</div>
        </a-form-model-item>
        <a-form-model-item label="售后服务">
            <template v-for="(item,index) in form.goodsLabels">
              <a-checkbox  :key="index" :checked="item.checked" @change="(e)=>{changeGoodsLabels(e,item)}">
                {{item.labelName}}
              </a-checkbox>
            </template>
        </a-form-model-item>
        <a-form-model-item  v-if="hasBusProp('sell')" label="预售">
            <a-row>
              <a-col>
                <a-checkbox v-model="formBusProp['sell'].checked" @change="changeSellChecked">
                  下单后需过段时间才能发货
                </a-checkbox>
                <br/>
                <a-radio-group defaultValue="presell" v-if="formBusProp['sell'].checked" v-model="formBusProp['sell'].businessProperty[0].propertyCode">
                  <a-radio v-for="(item,index) in formBusProp['sell'].businessProperty" :key="index" :value="item.propertyCode">
                    {{item.propertyName}}
                  </a-radio>
                </a-radio-group>
              </a-col>
            </a-row>
        </a-form-model-item>
        <a-form-model-item v-if="hasBusProp('sell') && formBusProp['sell'].checked && formBusProp['sell'].businessProperty[0].propertyCode==='presell'" label="订金尾款设置">
            <a-row>
              定金支付时间
              &emsp;<a-date-picker :disabled-date="disabledDateStart" :valueFormat="dateFormat" :format="dateFormat" @change="(e)=>{changeDate(e,'gmtPresellStart')}" v-model="formBusProp['sell'].goodsEx.presell.gmtPresellStart" />&emsp;至
              &emsp;<a-date-picker :disabled-date="disabledDateStart"  :valueFormat="dateFormatEnd"  :format="dateFormatEnd"  @change="(e)=>{changeDate(e,'gmtPresellEnd')}" v-model="formBusProp['sell'].goodsEx.presell.gmtPresellEnd" />
              <br/>
              尾款支付时间
              &emsp;<a-date-picker :disabled-date="disabledDateStart"  :valueFormat="dateFormat" :format="dateFormat"  @change="(e)=>{changeDate(e,'gmtPayStart')}" v-model="formBusProp['sell'].goodsEx.presell.gmtPayStart" />&emsp;至
              &emsp;<a-date-picker :disabled-date="disabledDateStart" :valueFormat="dateFormatEnd"  :format="dateFormatEnd"   @change="(e)=>{changeDate(e,'gmtPayEnd')}" v-model="formBusProp['sell'].goodsEx.presell.gmtPayEnd" />
                <br/>
              &emsp;&emsp;&emsp;&emsp;订金 &emsp;<a-input-number  @change="(e)=>{changeDate(e,'presellPrice')}" defaultValue="" style="width:180px;" v-model="formBusProp['sell'].goodsEx.presell.presellPrice" placeholder="请输入" />&emsp;元
            </a-row>
            <a-form-model-item prop="goodsEx"></a-form-model-item>
        </a-form-model-item>
        <a-form-model-item v-if="hasBusProp('sell') && formBusProp['sell'].checked && formBusProp['sell'].businessProperty[0].propertyCode==='presellAll'" label="全款预售设置">
            <a-row>
              全款支付时间
              &emsp;<a-date-picker :disabled-date="disabledDateStart" :valueFormat="dateFormat" :format="dateFormat" @change="(e)=>{changeDate(e,'gmtPayStart')}" v-model="formBusProp['sell'].goodsEx.presell.gmtPayStart" />&emsp;至
              &emsp;<a-date-picker :disabled-date="disabledDateStart" :valueFormat="dateFormatEnd" :format="dateFormatEnd" @change="(e)=>{changeDate(e,'gmtPayEnd')}" v-model="formBusProp['sell'].goodsEx.presell.gmtPayEnd" />
            </a-row>
            <a-form-model-item prop="goodsEx"></a-form-model-item>
        </a-form-model-item>
        <a-form-model-item v-if="hasBusProp('sell') && formBusProp['sell'].checked" label="发货时间">
            <a-row>
              发货时间&emsp;&emsp;
              &emsp;<a-date-picker :disabled-date="disabledDateStart" :valueFormat="dateFormat" :format="dateFormat" @change="(e)=>{changeDate(e,'gmtDeliveryStart')}" v-model="formBusProp['sell'].goodsEx.presell.gmtDeliveryStart" />&emsp;至
              &emsp;<a-date-picker :disabled-date="disabledDateStart" :valueFormat="dateFormatEnd" :format="dateFormatEnd" @change="(e)=>{changeDate(e,'gmtDeliveryEnd')}" v-model="formBusProp['sell'].goodsEx.presell.gmtDeliveryEnd" />
            </a-row>
            <a-form-model-item prop="goodsExDeliveryTime"></a-form-model-item>
        </a-form-model-item>
        <a-form-model-item label="起售"  v-show="programName!=='tea'">
            <a-row>
              商品至少
              &emsp;<a-input v-model="form.buyMin" style="width:80px;" />&emsp;件起售，每次递增
              &emsp;<a-input v-model="form.ladder" style="width:80px;" />&emsp;件
            </a-row>
            <div slot="extra" style="line-height:20px;">起售数量超出商品库存时，买家无法购买该商品<br/></div>
        </a-form-model-item>
        <a-form-model-item v-if="hasBusProp('order')" label="下单模式" v-show="programName!=='tea'">
            <a-row>
              <a-col :span="6">
                <a-radio-group defaultValue="single" v-model="formBusProp['order'].checkedVal" @change="changeOrderVal">
                  <a-radio v-for="(item,index) in formBusProp['order'].businessProperty" :key="index" :value="item.propertyCode">
                    {{item.propertyName}}
                  </a-radio>
                </a-radio-group>
              </a-col>
            </a-row>
            <div slot="extra" style="line-height:20px;">立即购买的商品，在商品详情页会直接下单进入订单提交<br/></div>
        </a-form-model-item>
        <a-form-model-item v-if="hasBusProp('vip')" label="限购" v-show="programName!=='tea'">
            <a-row>
              <a-col>
                <a-checkbox @change="changeRestrictedbuy" v-model="formBusProp['vip'].checked">
                  只允许特定用户购买
                </a-checkbox>
                <br/>
                <a-radio-group v-if="formBusProp['vip'].checked" v-model="formBusProp['vip'].checkedVal">
                  <a-radio v-for="(item,index) in formBusProp['vip'].businessProperty" :key="index" :value="item.propertyCode">
                    {{item.propertyName}}
                  </a-radio>
                </a-radio-group>
              </a-col>
            </a-row>
        </a-form-model-item>
      </div>
      <div v-show="stepShow===3" class="detailContent">
        <div class="baseInfo">商品详情描述</div>
        <a-form-model-item label="详情描述" :wrapper-col="{ span:16}">
          <div style="min-width:800px; display:flex; justify-content:center;">
            <div class="content_review_father">
                <p>商品详情预览效果</p>
                <iframe class="content_review"></iframe>
            </div>
            <Editor v-model="form.goodsDetail"></Editor>
          </div>
        </a-form-model-item>
      </div>
       <div v-show="stepShow===2" style="display:flex; justify-content: center; margin-top:30px;">

          <a-button @click="toNextStep" type="primary">
            下一步，填写商品详情
          </a-button>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
      </div>
      <div v-show="stepShow===3" style="display:flex; justify-content: center; margin-top:30px;">
        <a-button @click="()=>{ stepShow=2}" type="primary">
            返回上一步
        </a-button>
        &emsp;
        <a-button @click="validForm" type="primary">
          {{this.$route.query.id?"保存修改":"发布商品"}}
        </a-button>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
      <!-- ============ -->

    </a-form-model>
    <!-- 选规格  或者   添加新规格 -->
    <SelectSpecOrAddSpec @change="setSelectedSpecList" ref="goodSpecifications"></SelectSpecOrAddSpec>
    <!-- 添加商品属性 -->
    <!-- 商品属性和规格值 -->
    <EditSpec @needReflash="needReflash" ref="refEditSpec"></EditSpec>

    <!-- 选择分类弹出框 -->
    <a-modal
      title="添加商品分类"
      :visible="visible"
      :width="1000"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :footer=null
    >
      <SelectGoodsClass @idGoyGoodsChange="idGoyGoodsChange"></SelectGoodsClass>
    </a-modal>

    <!-- 发布成功后  去向弹窗 -->
    <a-modal v-model="jumperVisible" :footer="null" title="商品发布成功">
      <a-result
            status="success"
            title="恭喜您，您的商品发布成功!"
            sub-title="你还可以进行以下操作。。。"
        >
            <template #extra>
                <a-button key="buy" @click="$utils.linkTo('/shop/goods/offlineList')">
                    去仓库上架该商品
                </a-button>
                <a-button key="console" type="primary" @click="$router.go(0)">
                    继续发布商品
                </a-button>

            </template>
        </a-result>
    </a-modal>
  </div>
</template>

<script>
import Step from './components/step'
import GoodsProperties from './components/goodsProperties'
import SelectSpecOrAddSpec from './components/selectSpecOrAddSpec'
import EditSpec from './components/editSpec'
import SelectGoodsClass from './components/selectGoodsClass'
import Editor from '@/components/tinyMCE.vue'
import ImageManage from '@/components/imageManage/index.vue'
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

// import debounce from 'lodash/debounce'
export default {
  components: { GoodsProperties, SelectSpecOrAddSpec, EditSpec, Step, SelectGoodsClass, Editor, ImageManage },
  data () {
    let checkPending
    let checkPropertyDTOS = (rule, value, callback) => {
      clearTimeout(checkPending)
      console.log(this.form.goodsProperties, 'fdfdfdfdf')
      checkPending = setTimeout(() => {
        for (const { name, idGoProperty, required } of this.form.goodsProperties) {
          if (required === 1 && !name && !idGoProperty) {
            return callback(new Error('请填写必填项'))
          }
        }
        callback()
      }, 100)
    }
    let checkGoodsEx = (rule, value, callback) => {
      clearTimeout(checkPending)

      const presellInfo = this.formBusProp.sell.goodsEx.presell
      console.log(presellInfo, 'presellInfo')
      let presellCode = this.formBusProp.sell.businessProperty[0].propertyCode
      const { gmtDeliveryEnd, gmtDeliveryStart, gmtPayEnd, gmtPayStart, gmtPresellEnd, gmtPresellStart, presellPrice } = { ...presellInfo }

      console.log(gmtDeliveryEnd, gmtDeliveryStart, gmtPayEnd, gmtPayStart, gmtPresellEnd, gmtPresellStart, presellPrice)

      if (!gmtPayStart || !gmtPayEnd) return callback(new Error('请填写尾款或全款支付时间'))

      let startTime = moment(gmtPayStart)
      let endTime = moment(gmtPayEnd)
      const diff1 = moment(endTime).diff(moment(startTime), 'minutes')
      console.log(diff1, 'diff1')
      if (diff1 < 0) return callback(new Error('尾款或全款结束时间不能大于开始时间'))
      if (presellCode === 'presellAll') return callback()

      // 下面是走 presell验证
      // 下面是走 presell验证
      if (!gmtPresellStart || !gmtPresellEnd) return callback(new Error('请填写定金支付时间'))

      startTime = moment(gmtPresellStart)
      endTime = moment(gmtPresellEnd)
      const diff2 = moment(endTime).diff(moment(startTime), 'minutes')
      if (diff2 < 0) return callback(new Error('订金结束时间不能大于开始时间'))

      startTime = moment(gmtPresellEnd)
      endTime = moment(gmtPayStart)
      const diff3 = moment(endTime).diff(moment(startTime), 'minutes')
      if (diff3 < 0) return callback(new Error('尾款支付开始时间不能小于订金支付结束时间'))

      if (!presellPrice) return callback(new Error('请填写订金'))
      const price = this.form.goProductList[0].price
      const goProductList = this.form.goProductList
      console.log(price, goProductList, 'price')
      if (goProductList.length > 0) {
        for (const { price } of goProductList) {
          if (presellPrice >= price) return callback(new Error('订金不能大于等于商品最低价格'))
        }
      } else {
        if (presellPrice >= price) return callback(new Error('订金不能大于等于商品最低价格'))
      }
      return callback()
    }
    let checkDeliveryTime = (rule, value, callback) => {
      clearTimeout(checkPending)
      const presellInfo = this.formBusProp.sell.goodsEx.presell
      const { gmtDeliveryEnd, gmtDeliveryStart, gmtPayEnd } = { ...presellInfo }
      if (!gmtDeliveryEnd || !gmtDeliveryStart) return callback(new Error('请填写发货时间'))

      let startTime = moment(gmtPayEnd)
      let endTime = moment(gmtDeliveryStart)
      let diff1 = moment(endTime).diff(moment(startTime), 'minutes')
      if (diff1 < 0) return callback(new Error('发货开始时间不能大于尾款支付结束时间'))

      let startTime2 = moment(gmtDeliveryStart)
      let endTime2 = moment(gmtDeliveryEnd)
      let diff2 = moment(endTime2).diff(moment(startTime2), 'minutes')
      if (diff2 < 0) return callback(new Error('结束时间不能大于开始时间'))
      return callback()
    }
    let checkProductList = (rule, value, callback) => {
      // 此处修改只有 价格是必填
      clearTimeout(checkPending)
      value.forEach((element, index) => {
        const { price, stock, weight, volume, marketPrice, costPrice, productBarcode, productNo } = { ...element }
        if (!price) {
          return callback(new Error(`请输入第${index + 1}个规格的销售价格`))
        }
        if (!stock) {
        //   return callback(new Error(`请输入第${index + 1}个规格的库存`))
        }
        if (!weight) {
        //   return callback(new Error(`请输入第${index + 1}个规格的重量`))
        }
        if (!volume) {
        //   return callback(new Error(`请输入第${index + 1}个规格的体积`))
        }
        if (!marketPrice) {
        //   return callback(new Error(`请输入第${index + 1}个规格的市场价`))
        }
        if (!costPrice) {
        //   return callback(new Error(`请输入第${index + 1}个规格的成本价`))
        }
        if (!productBarcode) {
        //   return callback(new Error(`请输入第${index + 1}个规格的规格条码`))
        }
        if (!productNo) {
        //   return callback(new Error(`请输入第${index + 1}个规格的规格编码`))
        }
      })
      return callback()
    }
    return {
      imgListSwitchChecked: false, // 规格图片选择上传的开关
      halfCheckedGoyIds: {}, // tree 半选状态绑定数值 {checked:[],halfChecked:[]}
      programName: 'tea', // 项目名，用来控制 定制显示与隐藏   tea茶窖
      checkedPresell: false,
      presellVal: 'presell',
      formBusProp: undefined,
      goodsLabels: [],
      businessPropertyType: [],
      isEditorAdd: 'add',
      dateFormat: 'YYYY-MM-DD 00:00:00',
      dateFormatEnd: 'YYYY-MM-DD 23:59:59',
      stepShow: 2,
      showVideo: false,
      videoList: [],
      fileList: [],
      confirmLoading: false,
      businessTypeList: [],
      visible: false,
      jumperVisible: false,
      grandData: [], // 品牌
      saving: false,
      treeData: [],
      categoryLeverListValue: '', // 服装>>短袖>>儿童短袖
      goyPropertyListVals: [], // 属性
      goodsLabelsList: [], // 商品标签列表
      unitItemList: [], // 单位列表
      imgWH: false, // 上传图片的宽高
      content: '',
      shangpinFabu: '1',
      previewImage: '',
      previewVisible: false,
      disabledStatus: false, // 图片到达15张后不让上传
      selectedSpecList: [], // 被选中后添加了标记的库存
      selectGoyPropertyValueList: [], // 被选中,没被选中的的属性值
      categoryList: [], // 商品分类列表
      goodSpecificationsVisible: true, // 规格容器是否可见
      colonBool: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        goyVirtualIds: [], // 虚拟分类
        businessTypeCode: '',
        idGoyGoods: '',
        intro: '',
        imgUrls: [], // 商品图片
        idGoBrand: '', // 品牌id
        goodsNo: this.$utils.codeGenerator('', 6), // 商品编码
        goodsBarcode: '', // 商品条码
        traceable: 0, // 0否1是 是否可以溯源
        goProductList: [
          {
            price: '',
            stock: '',
            weight: '',
            volume: '',
            marketPrice: '',
            costPrice: '',
            productBarcode: '',
            productNo: '',
            imgUrls: [],
            goSpecificationIds: [],
            specification: '',
            expressDay: ''// 预计发货天数
          }
        ], // 货品列表
        goodsProperties: [], // 商品属性列表
        goodsLabels: [], // 商品标签 假一赔十  七天保障
        goodsDetail: '',

        saleType: 2, // 立即上架
        gmtOnSell: undefined,
        taskOnSell: 0, // 定时下架
        title: '',
        parentId: undefined, // 这是个什么？？？？？？？？？？？？？？？？
        buyMin: undefined,
        buyMax: undefined,
        ladder: undefined,

        videoUrl: '',
        shareIntro: '', // 分享信息
        name: '',
        // goodsType: 2,
        // ciq: '',
        // dutyRate: 0,
        // region: undefined,
        // date1: undefined,
        // type: [],
        // resource: '',
        volume: '',
        weight: ''
      },
      rules: {
        goodsProperties: [
          { validator: checkPropertyDTOS, trigger: 'change' }
        ],
        imgUrls: [
          { required: true, message: '请上传商品图', trigger: 'blur' }
        ],
        idGoyGoods: [
          { required: true, message: '请选择商品分类', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '商品标题名称长度至少3个字符，最长200个汉字。', trigger: 'blur' },
          { min: 3, max: 200, message: '至少3个字符，最长200个汉字', trigger: 'blur' }
        ],
        ciq: [
          { required: true, message: '进口商品需要填定CIQ码和税率，税率默认为0，请注意设置销售价格是含税价格。', trigger: 'blur' }
        ],
        jiaGeKuCun: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        goProductList: [
          { validator: checkProductList, trigger: 'change' }
        ],
        goodsEx: [
          { validator: checkGoodsEx, trigger: 'change' }
        ],
        goodsExDeliveryTime: [
          { validator: checkDeliveryTime, trigger: 'change' }
        ]
      },
      columns: [
        {
          title: '规格',
          dataIndex: 'nameList',
          key: 'nameList',
          scopedSlots: { customRender: 'nameList' }
        },
        {
          slots: { title: 'customTitle' },
          dataIndex: 'price',
          key: 'price',
          scopedSlots: { customRender: 'price' }
        },
        {
          title: '库存',
          dataIndex: 'stock',
          key: 'stock',
          scopedSlots: { customRender: 'stock' }
        },
        {
          title: '预计发货天数',
          dataIndex: 'expressDay',
          key: 'expressDay',
          scopedSlots: { customRender: 'expressDay' }
        },
        {
          title: '重量(kg)',
          dataIndex: 'weight',
          key: 'weight',
          scopedSlots: { customRender: 'weight' }
        },
        {
          title: '体积m³',
          dataIndex: 'volume',
          key: 'volume',
          scopedSlots: { customRender: 'volume' }
        },
        {
          title: '市场价(元)',
          dataIndex: 'marketPrice',
          key: 'marketPrice',
          scopedSlots: { customRender: 'marketPrice' }
        },
        {
          title: '成本价(元)',
          dataIndex: 'costPrice',
          key: 'costPrice',
          scopedSlots: { customRender: 'costPrice' }
        },
        {
          title: '规格条码',
          dataIndex: 'productBarcode',
          key: 'productBarcode',
          scopedSlots: { customRender: 'productBarcode' }
        },
        {
          title: '规格编码',
          dataIndex: 'productNo',
          key: 'productNo',
          scopedSlots: { customRender: 'productNo' }
        },
        {
          title: '关联第三方商品ID',
          dataIndex: 'outId',
          key: 'outId',
          scopedSlots: { customRender: 'outId' }
        }
      ],
      columnsImg: [
        {
          title: '规格',
          dataIndex: 'nameList',
          key: 'nameList',
          scopedSlots: { customRender: 'nameList' }
        },
        {
          title: '图片列表',
          dataIndex: 'imgList',
          key: 'imgList',
          scopedSlots: { customRender: 'imgList' }
        }
      ],
      replaceFields: { // 树替换属性
        children: 'children',
        title: 'name',
        key: 'id'
      },
      options: [
        { label: '立即提审', value: '1' },
        { label: '暂不提审', value: '0' }
      ],
      plainOptions: ['正品保障', '假一赔十', '七天保障']
    }
  },
  watch: {
    //   虚拟分类 tree 控制表单 虚拟分类ids
    halfCheckedGoyIds (newVal) {
      this.form.goyVirtualIds = newVal.checked
    },
    'form.goodsDetail': {
      handler (newVal) {
        this.reviewIframe(newVal)
      },
      deep: true
    }
  },
  computed: {
    isSingleSpec () {
      if (this.form.goProductList.length === 0) {
        console.log('选择了多规格', this.form)
        return false
      }
      if (this.form.goProductList[0].goSpecificationIds.length === 0) {
        console.log('单规格', this.form)
        return true
      }
      console.log('多规格', this.form)
      return false
    }

  },
  async mounted () {
    this.getBusinessType()
    this.getvirtualGoyTree()
    if (this.$route.query.id) { // 如果id有值 说明是编辑页面  需要同步拿到数据
      this.isEditorAdd = 'edit'
      this.getGoodsInfo(this.$route.query.id)
    }
    if (this.$route.query.idGoyGoods && !this.$route.query.id) {
      this.idGoyGoods = this.$route.query.idGoyGoods
      this.goodsGoyGoodsList(this.$route.query.idGoyGoods)
      this.initProperties({ idGoyGoods: this.$route.query.idGoyGoods, goodsProperties: null })
    }
    this.getunitItemList()
    this.handleSearchPingpai()
    this.form.goodsLabels = await this.getgoodsLabelsList()
  },
  methods: {
    reviewIframe (htmlStr) {
      let iframe = document.querySelector('iframe')
      if (iframe) {
        let frameWin = document.querySelector('iframe').contentWindow
        let frameDoc = frameWin.document
        let frameBody = frameDoc.body
        var tempCss = ''
        tempCss += '<style type="text/css">'
        tempCss += 'body{width:100%;margin:0;}'
        tempCss += 'img{ max-width:100% !important;}'
        tempCss += 'p{ margin:0;padding:0;border:0px;}'
        tempCss += '</style>'
        frameBody.innerHTML = tempCss + htmlStr
      }
    },
    onStart () { this.drag = true },
    onEnd () { this.drag = false },
    async uploadFile (blobInfo, success, failure) {
      let self = this
      /* eslint-disable no-undef */
      let param = new FormData() // 创建form对象
      param.append('file', blobInfo.blob())
      console.log(param.get('file')) // FormData私有类对象，访问不到，可以通过get判断值是否传进去
      let config = {
        headers: self.uploadFileHeader
      }
      // 添加请求头
      axios.post(self.uploadFileUrl, param, config)
        .then(res => {
          console.log(res, 'res')
          if (res.status === 200) {
            success(res.data.result)
          } else {
            failure('HTTP Error: ')
          }
        })
    },
    changTableVal (e, record, str) {
      try {
        record[str] = e.target.value
      } catch (error) {
        record[str] = e
      }
      this.$refs.goodForm.validateField(['goProductList'])
      this.$forceUpdate()
    },
    disabledDateStart (current) {
      return current && current < moment().subtract(1, 'days')
    },
    changegoodsProperties (e) {
      console.log(e, 'eeeeeeeeeeeeeeeeeee')
      this.form.goodsProperties = e
      this.$forceUpdate()
    },
    initProperties (obj) {
      console.log(obj, '商品属性obj')
      this.$refs.goodsProperties.init(obj)
    },
    changeDate (e, str) {
      this.formBusProp.sell.goodsEx.presell[str] = e
      if (str === 'gmtDeliveryStart' || str === 'gmtDeliveryEnd') {
        this.$refs.goodForm.validateField(['goodsExDeliveryTime'])
      } else {
        this.$refs.goodForm.validateField(['goodsEx'])
      }
      this.$forceUpdate()
    },
    changeRestrictedbuy (e) {
      this.formBusProp.vip.checked = e.target.checked
      this.formBusProp.vip.checkedVal = 'vipbuy'
      this.$forceUpdate()
    },
    changeOrderVal (e) {
      this.formBusProp.order.checkedVal = e.target.value
      this.$forceUpdate()
    },
    changeSellChecked (e) {
      console.log(e)
      this.formBusProp.sell.checked = e.target.checked
      this.$forceUpdate()
    },

    setFormProperties (res) {
      console.log(res, '检查并设置form')
      this.form.goodsProperties = []
      res.forEach(element => {
        this.form.goodsProperties.push({
          idGoProperty: undefined,
          idGoyProperty: element.id,
          name: undefined
        })
      })
    },
    // 将商品包含特性放入对象
    // 1，切换商品类型  2.获取
    setBusPorp (res) {
      this.formBusProp = {}
      const formInfo = res
      let that = this
      // 为商品特性设置初始值
      let propList = []
      this.businessTypeList.forEach((item) => {
        if (item.businessTypeCode === this.form.businessTypeCode) {
          propList = item.businessPropertyType || []
          return false
        }
      })
      propList.forEach((item) => {
        console.log(item, 'item,,,,,,,,,')
        that.formBusProp[item.propertyTypeCode] = item
        console.log(this.formBusProp, 'his.formBusProp')
        if (item.propertyTypeCode === 'delivery') {
          item.businessProperty.forEach((it) => {
            it.checked = !formInfo// 新增默认选中   修改先不先中 后面再设置值
          })
        }
        if (item.propertyTypeCode === 'order') {
          item.checkedVal = 'single'
        }
        if (item.propertyTypeCode === 'vip') {
          item.checked = true
          item.checkedVal = 'vipbuy'
        }
        if (item.propertyTypeCode === 'sell') {
          item.checked = true // 不选中预售
          if (item.businessProperty.length > 1) {
            // this.$store.dispatch('showToast', {
            //   type: 'error', message: '预售值只能有一个。数据错误'
            // })
          }
          item.goodsEx = {}
          item.goodsEx.presell = {
            gmtPayStart: undefined,
            gmtDeliveryStart: undefined,
            memo: undefined,
            gmtPayEnd: undefined,
            gmtDeliveryEnd: undefined,
            gmtPresellStart: undefined,
            gmtPresellEnd: undefined,
            presellPrice: undefined
          }
          item.businessProperty.splice(1, 1)
        }
      })
      if (!formInfo) return
      // 如果传了formInfo 则为回显。需要重置 设置上面初始值中的内容
      console.log(formInfo, 'forminfo')
      console.log(this.formBusProp, 'this.formBusProp')
      formInfo.goodsBusinessProperties.forEach((formInfoItem) => {
        // 设置回显示order特性
        if (formInfoItem.propertyTypeCode === 'order') {
          this.formBusProp.order.checkedVal = formInfoItem.propertyCode
        }
        // 设置回显示restrictedbuy特性  限购
        if (formInfoItem.propertyTypeCode === 'vip') {
          this.formBusProp.vip.checked = true
          this.formBusProp.vip.checkedVal = formInfoItem.propertyCode
        }
        // 设置回显示sell特性  预售
        if (formInfoItem.propertyTypeCode === 'sell') {
          this.formBusProp.sell.checked = true
          this.formBusProp.sell.businessProperty[0].propertyCode = formInfoItem.propertyCode
          this.formBusProp.sell.goodsEx = {}
          this.formBusProp.sell.goodsEx.presell = formInfo.goodsEx.presell || formInfo.goodsEx.presellAll
          this.$forceUpdate()
        }
        // 设置物流回显
        if (formInfoItem.propertyTypeCode === 'delivery') {
          this.formBusProp.delivery.businessProperty.forEach(item => {
            if (item.propertyCode === formInfoItem.propertyCode) {
              item.checked = true
            }
          })
          this.$forceUpdate()
        }
      })
    },
    // 判断商品是否包含某个特性
    hasBusProp (busPropStr) {
      if (!this.formBusProp) return false
      if (!this.formBusProp[busPropStr]) return false
      return true
    },
    changeBusinessType (item) {
      this.form.businessTypeCode = item.businessTypeCode
      this.businessPropertyType = item.businessPropertyType
      this.setBusPorp()
    },
    setImgUrlPreview (res) {
      if (res && res.imgUrls && res.imgUrls.length) {
        res.imgUrls.forEach((item, index) => {
          this.fileList.push({
            uid: index, status: 'done', response: { result: item }, url: item, name: item
          })
        })
      }
    },
    setVideoList (res) {
      if (res && res.videoUrl) {
        this.videoList.push({
          uid: 1, status: 'done', response: { result: res.videoUrl }, url: res.videoUrl, name: res.videoUrl
        })
        this.showVideo = true
      }
    },
    changeImgUrl () {
      this.fileList.forEach((item, index) => {
        this.form.imgUrls = []
        this.form.imgUrls.push(item.response.result || item.url)
      })
    },
    async getBusinessType () {
      const res = await this.$store.dispatch('http', {
        api: 'businessTypeList'
      })
      console.log(res, 'businessTypeList')
      if (res) {
        this.businessTypeList = res
        this.businessTypeList.forEach((item) => {
          // 写死商品为实物商品
          if (item.businessTypeCode === 'normal') {
            this.form.businessTypeCode = item.businessTypeCode
            this.businessPropertyType = item.businessPropertyType
            if (!this.$route.query.id) {
              this.setBusPorp()// 加载实物商品的特性
            }
          }
        })
      }
    },
    idGoyGoodsChange (e) {
      this.handleOk()
      // 去加载该分类  特有的属性值
      this.initProperties({ idGoyGoods: e, goodsProperties: null })
      this.goodsGoyGoodsList(e)
      setTimeout(() => {
        this.$refs.goodForm.validateField(['idGoyGoods'])
      }, 500)
    },

    handleOk (e) {
      console.log(1234123412343)
      this.confirmLoading = true
      setTimeout(() => {
        this.visible = false
        this.confirmLoading = false
      }, 100)
    },
    async handleSearchPingpai (val) {
      // console.log(val)
      const res = await this.$store.dispatch('http', {
        api: 'goBrandList',
        params: {
          name: val
        }
      })
      console.log(res)
      this.grandData = res
    },
    handleChangePingpai (e) {
      console.log(e)
      this.form.idGoBrand = e
    },
    handleChangeMainImgList (e) {
      if (e.file && e.file.status === 'done' && e.file.response) {
        this.form.imgUrls.push(e.file.response.result)
        this.$refs.goodForm.validateField(['imgUrls'])
      }
      if (e.file && e.file.status === 'error') {
        this.$store.dispatch('showToast', {
          type: 'error', message: '图片上传失败！'
        })
      }
    },
    handleMvChange (e) {
      console.log(e.fileList)
      this.videoList = e.fileList
      if (this.videoList.length) {
        if (this.videoList[0] && this.videoList[0].response && this.videoList[0].response.result) {
          this.form.videoUrl = this.videoList[0].response.result || ''
          this.$nextTick(() => {
            this.showVideo = true
          })
        }
      } else {
        this.form.videoUrl = ''
        this.$nextTick(() => {
          this.showVideo = false
        })
      }
      this.$refs.goodForm.validate()
    },
    async delItem (oobj) {
      console.log(oobj)

      if (oobj.item.checked) {
        this.$store.dispatch('showToast', {
          type: 'error',
          message: '删除被选中的值，可能会导致错误哦。请取消勾选，并保存商品才进行删除吧！'
        })
        return
      }
      this.$store.dispatch('showConfirm', {
        message: '您确定要删除吗？',
        yes: async _ => {
          let appi = ''
          let qery = {}
          let parm = {}
          if (oobj.str === 'guige') {
            appi = 'updateSpec'
            console.log(oobj, '删除规格方法')
            let goSpecifications = this.$utils.clone(oobj.goySpec.item)
            goSpecifications.forEach((element, index) => {
              if (element.name === oobj.item.name) {
                goSpecifications.splice(index, 1)
              }
            })
            parm = {
              id: oobj.goySpec.id,
              name: oobj.goySpec.name,
              goSpecifications
            }
          }
          const res = await this.$store.dispatch('http', {
            api: appi,
            query: qery,
            params: parm
          })
          console.log(res)
          if (res) {
            this.$store.dispatch('showToast', {
              type: 'success',
              message: '删除成功'
            })
            if (oobj.str === 'guige') {
              this.selectedSpecList.forEach((itt1, index) => {
                itt1.item.forEach((itt2, index2) => {
                  if (itt2.id === oobj.item.id) {
                    this.selectedSpecList[index].item.splice(index2, 1)
                  }
                })
              })
            }
          }
        }
      })
    },
    async needReflash (oobj) {
      // 刷新规格值或者属性值
      console.log(oobj) // id: this.form.id, sing: this.actionUrls
      let httpV = {}
      let getedList = []
      if (oobj.sing === 'spec' || oobj.sing === 'spec0') {
        httpV = {
          api: 'goySpecValueVueGoyGoodsId',
          query: {
            id: this.form.idGoyGoods
          }
        }
        getedList = this.goySpecificationList
        console.log(getedList, 'aaabbbbccc')
      }
      const res = await this.$store.dispatch('http', httpV)
      getedList = res.records ? res.records : res
      let newSpcificationsList = {}

      getedList.forEach(item => {
        if (item.id === oobj.id) {
          newSpcificationsList = item
        }
      })
      console.log(newSpcificationsList)
      if (oobj.sing === 'spec' || oobj.sing === 'spec0') {
        let obj = {}
        let indexs = ''
        this.selectedSpecList.forEach((item, index) => {
          if (item.id === newSpcificationsList.id) {
            obj = this.$utils.clone(item)
            indexs = index
          }
        })
        if (obj.item) {
          obj.item.forEach(item => {
            newSpcificationsList.item.forEach(item1 => {
              if (item1.id === item.id) {
                item1.checked = item.checked
              }
            })
          })
        }
        this.selectedSpecList[indexs] = newSpcificationsList
        this.selectedSpecList = [...this.selectedSpecList]
      }
    },
    async goyPropertyList (callback = () => { }) { // 获取属性
      const res = await this.$store.dispatch('http', {
        api: 'getPropertyList',
        query: {
          goyGoodsId: this.form.idGoyGoods
        },
        complete: () => {
          callback()
        }
      })
      this.goyPropertyListVals = res
    },
    // async goySpecificationList () { // 获取规格
    //   // const res = await this.$store.dispatch('http', {
    //   //   api: 'goySpecificationList',
    //   //   complete: () => {
    //   //   }
    //   // })
    //   // this.goySpecificationList = res.records
    //   console.log(this.form)
    //   const goyGoodsId = this.$route.query.idGoyGoods ? this.$route.query.idGoyGoods : this.form.idGoyGoods
    //   const res2 = await this.$store.dispatch('http', {
    //     api: 'goySpecValueVueGoyGoodsId',
    //     query: {
    //       id: goyGoodsId
    //     },
    //     complete: () => {
    //     }
    //   })
    //   console.log(res2)
    //   this.goySpecificationList = res2
    // },
    async getGoodsInfo (id) {
      const res = await this.$store.dispatch('http', {
        api: 'getGoodInfo',
        query: { id: id }
      })
      this.form = res
      console.log(res, '查看信息回')

      // 虚拟tree 回显
      const { goyVirtualIds } = { ...res }
      this.halfCheckedGoyIds = { checked: [...goyVirtualIds], halfChecked: [] }

      // 属性回显

      const { idGoyGoods, goodsProperties } = { ...res }
      this.initProperties({ idGoyGoods: idGoyGoods, goodsProperties: goodsProperties })

      let resClone = this.$utils.clone(res)

      // 回显示 form.goodsLabels
      const allLabels = await this.getgoodsLabelsList() // 重新设置goodsLabels
      let selectLabes = []
      this.form.goodsLabels.forEach(element => {
        selectLabes.push(element.labelCode)
      })
      this.form.goodsLabels = allLabels
      this.form.goodsLabels.forEach(element => {
        element.checked = selectLabes.includes(element.labelCode)
      })

      // 查找标准分类 idGoyGoods
      this.goodsGoyGoodsList(res.idGoyGoods)

      // 回显示 最小购买  递增
      // this.form.buyMin = res.goProductList[0].buyMin
      // this.form.ladder = res.goProductList[0].ladder

      this.form.goProductList = resClone.goProductList

      console.log(this.form, '回显后  看一下productlist')
      this.setBusPorp(res)
      // this.goodsProperties(this.form) // 属性回显
      this.setImgUrlPreview(res)// 设置 图片回示
      this.setVideoList(res)// 设置 视频回显

      // this.dutyRate = this.form.dutyRate / 100
      console.log(res)
      this.goyPropertyList()
      this.handleSearchPingpai()
      // this.content = this.form.goodsDetail
      if (this.form.goProductList.length) {
        this.form.goProductList.forEach((item, index) => { // 添加nameList
          // 图片回显
          item.imgList = []
          if (item && item.gomProductImageList && item.gomProductImageList.length) {
            item.gomProductImageList.forEach((imgItem) => {
              item.imgList.push({ url: imgItem.imgUrl, uid: imgItem.id, name: 'image.png', status: 'done' })
            })
            // 更改原有图片LIST
            let arrarr = []
            item.gomProductImageList.forEach((item2) => {
              arrarr.push(item2.imgUrl)
            })
            item.imgUrls = arrarr
          }

          // 更改名称显示
          item.nameList = []
          item.specification.split(',').forEach((it) => {
            item.nameList.push({ name: it })
          })
          // 上传了规格图片
          // 更改样式 显示出图片
          if (item.imgList.length > 0) {
            this.$refs.tableBlock.style.height = 'auto'
            this.imgListSwitchChecked = true
          }
        })
      }

      // 设置被 选中的规格
      this.setSpec(this.form)
      this.goodsGoyGoodsList(this.form.idGoyGoods)
    },
    async setSpec (obj) {
      console.log(obj, 'form 内容')
      const { idGoyGoods, goProductList } = { ...obj }
      const res2 = await this.$store.dispatch('http', {
        api: 'goySpecValueVueGoyGoodsId',
        query: {
          id: idGoyGoods
        }
      })
      this.selectedSpecList = []
      let selectedGoySpecList = goProductList && goProductList[0] && goProductList[0].goySpecificationIds
        ? goProductList[0].goySpecificationIds : []
      res2.forEach(element => {
        if (selectedGoySpecList.includes(element.id)) {
          this.selectedSpecList.push(element)
        }
      })
      let selectedSpecVal = []
      for (const { goSpecificationIds } of goProductList) {
        selectedSpecVal = Array.from(new Set([...selectedSpecVal, ...goSpecificationIds]))
      }
      for (const { item } of this.selectedSpecList) {
        item.forEach(element => {
          if (selectedSpecVal.includes(element.id)) {
            element.checked = true
          }
        })
      }
    },
    async validForm () {
      this.$refs.goodForm.validate(valid => {
        if (valid) {
          // console.log(this.$route.query.id)
          if (this.$route.query.id && this.saving === false) {
            this.saveAndEdit('editGoods', { id: this.$route.query.id })
          } else if (this.saving === false) {
            this.saveAndEdit('saveGoGoods')
          } else {
            console.log(123412341241234123412341234132432)
          }
        } else {
          console.log('验证不通过')
          this.$store.dispatch('showToast', {
            type: 'error',
            message: '请填写完整信息'
          })
        }
      })
    },
    async toNextStep () {
      const a = await this.$refs.goodsProperties.validate()
      console.log(a, '111aaa')
      if (!a) {
        this.$store.dispatch('showToast', {
          type: 'error',
          message: '请填写商品属性'
        })
        return
      }
      this.$refs.goodForm.validate(valid => {
        if (valid) {
          this.stepShow = 3
        } else {
          console.log('验证不通过')
          this.$store.dispatch('showToast', {
            type: 'error',
            message: '请填写完整信息'
          })
        }
      })
    },
    async saveAndEdit (api, qery) {
      console.log(api, qery, '发布商品')
      let cloneForm = this.$utils.clone(this.form)

      // 改服务值  假一赔十
      // cloneForm.goodsLabelDtos.forEach((item, index) => {
      //   if (!item.checked) {
      //     cloneForm.goodsLabelDtos.splice(index, 1)
      //   }
      // })

      // 将预售值设置进form
      if (this.formBusProp && this.formBusProp.sell && this.formBusProp.sell.businessProperty && this.formBusProp.sell.businessProperty[0] && this.formBusProp.sell.businessProperty[0].propertyCode) {
        let sellKey = this.formBusProp.sell.businessProperty[0].propertyCode
        cloneForm.goodsEx = {}
        // presell 和 presellAll参数不一样 需要显示不同参数
        if (sellKey === 'presell') {
          cloneForm.goodsEx[sellKey] = this.formBusProp.sell.goodsEx.presell
        } else {
          const { gmtPayStart, gmtPayEnd, gmtDeliveryStart, gmtDeliveryEnd } = { ...this.formBusProp.sell.goodsEx.presell }
          cloneForm.goodsEx[sellKey] = { gmtPayStart, gmtPayEnd, gmtDeliveryStart, gmtDeliveryEnd }
        }
      }

      // t设置商品的  被 选择的特性的list
      cloneForm.propertyCodeList = []
      let formBusProp = this.formBusProp
      // 传入是否立即下单值
      if (formBusProp && formBusProp.order && formBusProp.order.checkedVal !== '') {
        cloneForm.propertyCodeList.push(
          formBusProp.order.checkedVal
        )
      }
      // 传入限购值
      if (formBusProp && formBusProp.vip && formBusProp.vip.checked) {
        cloneForm.propertyCodeList.push(
          formBusProp.vip.checkedVal
        )
      }
      // 传预售特性
      if (formBusProp && formBusProp.sell && formBusProp.sell.checked) {
        cloneForm.propertyCodeList.push(
          formBusProp.sell.businessProperty[0].propertyCode
        )
      }
      if (formBusProp && formBusProp.delivery && formBusProp && formBusProp.delivery.businessProperty) {
        // 传入物流特性
        this.formBusProp.delivery.businessProperty.forEach((item) => {
          if (item.checked) {
            cloneForm.propertyCodeList.push(item.propertyCode)
          }
        })
      }

      // 删除goodsLabels中无用属性
      let arrgoodsLabels = []
      cloneForm.goodsLabels.forEach((lbItem) => {
        if (lbItem.checked) {
          arrgoodsLabels.push({ labelCode: lbItem.labelCode })
        }
      })
      cloneForm.goodsLabels = arrgoodsLabels

      // 删除goodsProperties中无用属性
      cloneForm.goodsProperties.forEach((item, index) => {
        item.required = undefined
        item.inputType = undefined
      })

      // 删除goProductList 中无用属性
      cloneForm.goProductList.forEach((item, index) => {
        delete item.nameList
      })

      this.saving = true
      // this.saving = false

      const res = await this.$store.dispatch('http', {
        api: api,
        query: qery,
        params: {
          ...cloneForm
        },
        complete: () => {
          setTimeout(() => {
            this.saving = false
          }, 300)
        },
        success: () => {
          this.$store.dispatch('showToast', {
            type: 'success',
            message: '操作成功'
          })
        },
        error: () => {
          this.$store.dispatch('showToast', {
            type: 'success',
            message: '发布失败'
          })
        }
      })
      console.log(res)
      if (res && api === 'editGoods') {
        setTimeout(() => {
          window.close()
        }, 1000)
      }
      if (res && api === 'saveGoGoods') {
        // 发布完成跳转到另一个页面
        this.jumperVisible = true
      }
    },
    onChange (item) {
      console.log(item)
      item.checked = !item.checked
      this.$forceUpdate()
    },
    changeFormBusProp (e, str, item) {
      console.log(e, str, item)
      item.checked = !item.checked
      this.$forceUpdate()
    },
    handleCancel () {
      this.previewVisible = false
      this.visible = false
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' // 这里获取上传文件的类型
        if (!isJpgOrPng) {
          this.$store.dispatch('showToast', {
            type: 'error',
            message: '图片格式不正确'
          })
          return reject(new Error(0)) // 如有问题那咱们通过reject 函数传一个false阻止他的上传
        }
        if (isJpgOrPng) {
          let reader = new FileReader() // 这个是通过原生的方式来获取图片的宽高
          reader.readAsDataURL(file)
          reader.onload = () => {
            const image = new Image()
            image.src = reader.result
            image.onload = () => {
              let w = image.width
              let h = image.height
              if (w !== h) {
                this.$store.dispatch('showToast', {
                  type: 'error',
                  message: '请上传1:1的图片'
                })
                return reject(new Error(0)) // //如有问题那咱们通过reject 函数传一个false阻止他的上传
              } else if (file.size > 500 * 1024) { // 102400
                this.$store.dispatch('showToast', {
                  type: 'error',
                  message: '图片大小不能超过500K,建议200k以内'
                })
                return reject(new Error(0)) // //如有问题那咱们通过reject 函数传一个false阻止他的上传
              } else {
                return resolve(true) // 如没有问题了那咱们就通过resolve函数添加一个true 让文件上传
              }
            }
          }
        }
      })
    },
    beforeUpload1 (file, record) {
      this.getIsBiLi(file)

      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        // this.$message.error('图片格式不正确')
        this.$store.dispatch('showToast', {
          type: 'error',
          message: '图片格式不正确'
        })
      }
      const isLt2M = file.size / 1024 < 100
      if (!isLt2M) {
        this.$store.dispatch('showToast', {
          type: 'error',
          message: '图片大小不能超过100K,建议50K以内'
        })
      }
      return isJpgOrPng && isLt2M
    },
    addGuigeVal (item) { // 添加规格值
      item.actionUrls = 'spec'
      this.$refs.refEditSpec.init(item)
    },
    onSubmit () {
      console.log('submit!', this.form)
    },
    showGoodModal () { // 弹出添加规格窗口
      if (!this.form.idGoyGoods || this.form.idGoyGoods === '') {
        this.$store.dispatch('showToast', {
          type: 'error',
          message: '请先选择商品分类'
        })
        return
      }
      let selectedRowKeyArr = []
      console.log(this.selectedSpecList, 'aaabbbcccccc')
      this.selectedSpecList.forEach((it) => {
        selectedRowKeyArr.push(it.id)
      })
      this.$refs.goodSpecifications.goodSpecificationsVisible = true
      const goyGoodId = this.form.idGoyGoods
      this.$refs.goodSpecifications.goyGoodsId = goyGoodId
      this.$refs.goodSpecifications.form.goyId = goyGoodId
      this.$refs.goodSpecifications.getSpecList()
      this.$refs.goodSpecifications.selectedRowKeys = selectedRowKeyArr
    },
    // 加载列表
    async goodsGoyGoodsList (idGoyGoods) {
      const res = await this.$store.dispatch('http', {
        api: 'goodsGoyGoodsList',
        complete: () => {
        }
      })
      // lever1 name lever2 name   lever3name
      this.categoryList = res
      this.categoryList.forEach((item1, index1) => {
        if (item1.children) {
          item1.children.forEach((item2, index2) => {
            if (item2.children) {
              item2.children.forEach((item3, index3) => {
                if (item3.id === idGoyGoods) {
                  this.form.idGoyGoods = item3.id
                  this.categoryLeverList = [index1, index2, index3]
                  this.categoryLeverListValue = this.categoryList[index1].name + '>>' + this.categoryList[index1].children[index2].name + '>>' + this.categoryList[index1].children[index2].children[index3].name
                }
              })
            }
          })
        }
      })
    },
    // 加载店铺虚拟分类
    async getvirtualGoyTree (callback = () => { }) {
      const res = await this.$store.dispatch('http', {
        api: 'goyVirtualList',
        complete: () => {
          callback()
        }
      })
      this.treeData = res
    },
    // 获取单位
    async getunitItemList (callback = () => { }) {
      const res = await this.$store.dispatch('http', {
        api: 'unitItemList',
        complete: () => {
          callback()
        }
      })
      this.unitItemList = res
    },
    // 设置labels
    changeGoodsLabels (e, item) {
      console.log(e, item, 'e+item')
      item.checked = e.target.checked
      this.$forceUpdate()
    },
    // 获取labels
    async getgoodsLabelsList () {
      const res = await this.$store.dispatch('http', {
        api: 'goodsLabelsList'
      })
      let allLabelsSelect = []
      res.forEach(item => {
        allLabelsSelect.push({
          labelCode: item.code,
          labelName: item.name
        //   checked: true
        })
      })
      return allLabelsSelect
    },
    selectCategory () { // 返回上页选择分类
      this.visible = true
    },
    setSelectedSpecList (list) {
      console.log(list, '选择了规格值')
      list.forEach((it) => {
        console.log(it, '一项规格')
        let exist = 'no'
        this.selectedSpecList.forEach((it2) => {
          console.log('存在')
          if (it.id === it2.id) { //= =就是存在
            exist = 'yes'
          }
        })
        if (exist === 'no') { // 说明没有使用过这个规格
          console.log('不存在')
          this.selectedSpecList.push(it)
          console.log(it, 'it')
          console.log(this.selectedSpecList, ' this.selectedSpecList')
          // 如果添加了新的规格值  那么要删除所有的 this.form.goProducts
          this.form.goProductList = []
        }
      })
      console.log(this.selectedSpecList)
      this.selectedSpecList.forEach((it, index) => {
        let has = 'no' // 假设no应该删除掉这个不存在的it
        list.forEach((at) => {
          if (it.id === at.id) {
            has = 'yes'
          }
        })
        if (has === 'no') {
          this.selectedSpecList.splice(index, 1)
          this.form.goProductList = []
          let arrTrue = this.$utils.clone(this.selectedSpecList)
          console.log(arrTrue)
          arrTrue.forEach((it, index) => {
            it.item.forEach((it2, idx2) => {
              if (!it2.checked) {
                arrTrue[index].item.splice(idx2, 1)
              }
            })
          })
          this.getFinalArr('add', arrTrue)
          // 删除了规格项  那么要删除所有的 this.form.goProducts
        }
      })
      this.selectedSpecList = [...this.selectedSpecList]// 重新给自己赋值以刷 新dom
    },

    changeGuige (e, index, idx) { // 改变规格
      this.selectedSpecList[index].item[idx].checked = e.target.checked
      this.selectedSpecList = [...this.selectedSpecList]// 重新给自己赋值以刷 新dom
      this.selectedSpecList = this.$utils.clone(this.selectedSpecList)// 给新建的list只保留被选中的值
      let arr = this.$utils.clone(this.selectedSpecList) // defind a new arr get what changed
      let aa = arr[index].item[idx]
      arr[index].item = [aa]// 利用arr数组去计算 删除后或者增加后变化的商品规格
      arr.forEach((it, index1) => {
        if (index1 !== index) {
          let newList = []
          it.item.forEach((it2, index2) => {
            if (it2.checked) {
              newList.push(it2)
            }
          })
          it.item = newList
        }
      })
      if (e.target.checked) {
        // 添加了值
        this.getFinalArr('add', arr)
      } else {
        // 删除了值
        this.getFinalArr('del', [this.selectedSpecList[index].item[idx].id])
      }
    },
    getFinalArr (str, arr) {
      /* 方式二：多个数组求笛卡尔积 */

      if (str === 'add') {
        var array4 = []
        console.log(JSON.stringify(arr))
        arr.forEach((item, index) => {
          var newArr = []
          item.item.forEach((it) => {
            newArr.push(it)
          })
          array4.push(newArr)
        })
        console.log(array4)
        if (array4.length > 0) {
          var result = array4.reduce((last, current) => {
            const array = []
            last.forEach(par1 => {
              current.forEach(par2 => {
                if (typeof par1 === 'string') {
                  array.push(par1 + '_' + JSON.stringify(par2))
                } else {
                  array.push(JSON.stringify(par1) + '_' + JSON.stringify(par2))
                }
              })
            })
            return array
          })
          console.log(result, 'resuuuuutuuulllllllltltlt')
          var result2 = []
          result.forEach((item) => {
            if (typeof item === 'string') {
              item = item.split('_')
            }
            let arrFinal = []
            if (item.length > 0) {
              item.forEach((it) => {
                arrFinal.push(JSON.parse(it))
              })
            } else {
              arrFinal.push(item)
            }
            let obj = {}
            obj.nameList = arrFinal
            obj.goSpecificationIds = []
            arrFinal.forEach((it) => {
              obj.goSpecificationIds.push(it.id)
            })
            obj.price = '' // 在这里可以设置更多的属性
            obj.stock = '' // 在这里可以设置更多的属性
            obj.marketPrice = '' // 在这里可以设置更多的属性
            obj.costPrice = '' // 在这里可以设置更多的属性
            obj.volume = undefined // 在这里可以设置更多的属性
            obj.weight = undefined // 在这里可以设置更多的属性
            obj.productBarcode = '' // 在这里可以设置更多的属性
            obj.productNo = '' // 在这里可以设置更多的属性
            obj.imgList = []
            obj.imgUrls = []
            result2.push(obj)
          })
          this.form.goProductList = [...this.form.goProductList, ...result2]
        }
      }
      if (str === 'del') {
        console.log('del')
        console.log(this.form.goProductList)
        console.log(arr[0])
        let recordIndex = []
        this.form.goProductList.forEach((itemm, iindex) => {
          itemm.goSpecificationIds.forEach((itemm2) => {
            // console.log(itemm2)
            if (itemm2 === arr[0]) {
              console.log('true', this.form.goProductList[iindex])
              recordIndex.push(iindex)
            }
          })
        })
        console.log(recordIndex)
        console.log(recordIndex.indexOf(3))
        let newGoproductList = []
        for (let index = 0; index < this.form.goProductList.length; index++) {
          if (recordIndex.indexOf(index) === -1) {
            newGoproductList.push(this.form.goProductList[index])
          }
        }
        console.log(newGoproductList)
        this.form.goProductList = newGoproductList
        this.form.goProductList = [...this.form.goProductList]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content_review_father {
    p{
        margin-right:20px;
        width:375px;
        background:#9FCEFF;
        height: 40px;
        font-size: 14px;
        text-align: center;
    }
    .content_review {
        margin-right:20px;
        width:375px;
        padding:8px;
        height:760px;
        overflow-y:scroll;
        border: 1px solid #ccc;
        ::v-deep img {
            max-width: 100%;
        }
    }
}
.chosen {
  border:1px solid pink;
}
.drag__ct {
  display: flex;
  align-items: flex-start;
  .dragImgList {
    display: flex;
    flex:1;
    align-items: center;
    .item {
      cursor: move;
      width:104px;
      height:104px;
      padding:5px;
      margin-right:5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border:1px dashed #d9d9d9;
        img {
          width: 90px;
          height: 90px;
        }
        .del {
          position: absolute;
          cursor:pointer;
          display:none;
        }
    }
    .item:hover {
        img {
          filter:brightness(50%)
        }
        .del {
          display:block;
        }
    }
  }
}
.businessTypeList{
  padding-left: 40px;
  display:flex;
  div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color:#d9d9d9;
    border:1px solid #6b6b6b;
    border-radius:4px;
    padding:4px 10px;
    margin:40px 20px 20px 0;
    cursor:pointer;
    span:first-child{
      font-size:16px;
      font-weight:bold;
      // color:#108fd2;
      color:#6b6b6b;
    }

  }
  .active {
    background: rgb(192, 227, 255);
  }
}
.buzhou{
  margin-top: 10px;
  display: flex;
  border-radius: 5px;
  overflow:hidden;
  .buzhou-item{
    display: flex;
    justify-content:flex-start;
    flex: 1;
    background:#efefef;
    height:88px;
    .buzhouMess{
      padding-right: 20px;
      div:first-child{
        margin-top:10px;
        font-size: 22px;
      }
      div:last-child{
        font-size: 10px;
      }
    }
    .num{
      color: #ffffff;
      flex-grow: 0;
      flex-shrink: 0;
      width:55px;
      height:45px;
      div{
        margin-top:17px;
        margin-left: 20px;
        width:20px;
        height: 20px;
        border-radius: 50%;
        background:#74797d;
        display:flex;
        justify-content: center;
      }
    }
  }
  .active{
    background:#108fd2;
    color: #ffffff;
  }
}
.baseInfo {
  background: #f2f2f2;
  padding:10px 20px;
  margin-top: 30px;
  font-size: 20px;
}
.formModal{
  div.ant-form-item{
    border-bottom:1px solid #d9d9d9;
    div.ant-form-item{
      border:none;
      padding-bottom:0;
    }
  }
}

.ant-form-item-control-wrapper{
  border-left:1px solid red;
}
</style>
<style>
.formModal>div>div{
  /* padding-top:15px; */
}
.formModal>div>div>div.ant-form-item-label{
  padding-top:15px;
}
.formModal>div>div>div:last-child{
  padding:15px;
  border-left:1px solid #d9d9d9;
}
.guige{
  display:flex;
  justify-content:left;
  border-bottom:1px solid #d9d9d9;
}
.guige:last-child{
  border-bottom:none;
}
.guigeLabel{
  width:170px;
}
.guigeContent{
  align-items:left;
  padding-left:20px;
  display: flex;
  flex-wrap: wrap;
}
.guigeContent>span{
  margin-right: 50px;
}
.content{
 height:600px;
 overflow-y:auto;
}

</style>
