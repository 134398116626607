var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"layout":"inline","labelCol":{'style':'width:130px;'},"form":_vm.form}},_vm._l((_vm.propertyAtGoyGoodsList),function(item){return _c('div',{key:item.id,staticStyle:{"width":"430px","display":"inline-block"},attrs:{"required":false}},[(item.inputType === 'string')?_c('a-form-item',{staticStyle:{"width":"430px"},attrs:{"required":false}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.required===1),expression:"item.required===1"}],staticClass:"requiredStar"},[_vm._v("*")]),_vm._v(_vm._s(item.name))]),(item.inputType === 'string')?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            item.code,
            {
              validateTrigger: ['change', 'blur'],
              rules: [
                {
                  required: item.required===1,
                  whitespace: true,
                  message: '请输入',
                } ],
              initialValue:item.value
            } ]),expression:"[\n            item.code,\n            {\n              validateTrigger: ['change', 'blur'],\n              rules: [\n                {\n                  required: item.required===1,\n                  whitespace: true,\n                  message: '请输入',\n                },\n              ],\n              initialValue:item.value\n            },\n          ]"}],staticStyle:{"width":"250px"},attrs:{"placeholder":"请输入"},on:{"change":function (e){_vm.changePropVal(e,item)}}}):_vm._e(),_c('span',{staticClass:"unit"},[_vm._v(" "+_vm._s(item.unitName))])],1):_vm._e(),(item.inputType === 'number')?_c('a-form-item',{staticStyle:{"width":"430px"},attrs:{"required":false}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.required===1),expression:"item.required===1"}],staticClass:"requiredStar"},[_vm._v("*")]),_vm._v(_vm._s(item.name))]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            item.code,
            {
              validateTrigger: ['change', 'blur'],
              rules: [
                {
                  required: item.required===1,
                  whitespace: true,
                  message: '请输入',
                } ],
              initialValue:item.value
            } ]),expression:"[\n            item.code,\n            {\n              validateTrigger: ['change', 'blur'],\n              rules: [\n                {\n                  required: item.required===1,\n                  whitespace: true,\n                  message: '请输入',\n                },\n              ],\n              initialValue:item.value\n            },\n          ]"}],staticStyle:{"width":"250px","margin-right":"8px"},attrs:{"placeholder":"请输入"},on:{"change":function (e){_vm.changePropVal(e,item)}}}),_c('span',{staticClass:"unit"},[_vm._v(" "+_vm._s(item.unitName))])],1):_vm._e(),(item.inputType === 'boolean')?_c('a-form-item',{staticStyle:{"width":"430px"},attrs:{"required":false}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.required===1),expression:"item.required===1"}],staticClass:"requiredStar"},[_vm._v("*")]),_vm._v(_vm._s(item.name))]),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            item.code,
            {
              validateTrigger: ['change', 'blur'],
              rules: [
                {
                  required: item.required===1,
                  whitespace: true,
                  message: '请选择',
                } ],
              initialValue:item.value
            },
            { initialValue:'1' }
          ]),expression:"[\n            item.code,\n            {\n              validateTrigger: ['change', 'blur'],\n              rules: [\n                {\n                  required: item.required===1,\n                  whitespace: true,\n                  message: '请选择',\n                },\n              ],\n              initialValue:item.value\n            },\n            { initialValue:'1' }\n          ]"}],on:{"change":function (e){_vm.changePropVal(e,item)}}},[_c('a-radio',{attrs:{"value":"1"}},[_vm._v(" 是 ")]),_c('a-radio',{attrs:{"value":"0"}},[_vm._v(" 否 ")])],1),_c('span',{staticClass:"unit"},[_vm._v(" "+_vm._s(item.unitName))])],1):_vm._e(),(item.inputType === 'date')?_c('a-form-item',{staticStyle:{"width":"430px"},attrs:{"required":false}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.required===1),expression:"item.required===1"}],staticClass:"requiredStar"},[_vm._v("*")]),_vm._v(_vm._s(item.name))]),_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            item.code,
            {
              validateTrigger: ['change', 'blur'],
              rules: [
                {
                  required:  item.required===1,
                  whitespace: true,
                  message: '请选择日期',
                } ],
              initialValue:item.value
            } ]),expression:"[\n            item.code,\n            {\n              validateTrigger: ['change', 'blur'],\n              rules: [\n                {\n                  required:  item.required===1,\n                  whitespace: true,\n                  message: '请选择日期',\n                },\n              ],\n              initialValue:item.value\n            },\n          ]"}],staticStyle:{"width":"250px"},attrs:{"valueFormat":"YYYY-MM-DD 00:00:00","format":"YYYY-MM-DD 00:00:00"},on:{"change":function (e){_vm.changePropVal(e,item)}}}),_c('span',{staticClass:"unit"},[_vm._v(" "+_vm._s(item.unitName))])],1):_vm._e(),(item.inputType === 'radio')?_c('a-form-item',{staticStyle:{"width":"430px"},attrs:{"required":false}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.required===1),expression:"item.required===1"}],staticClass:"requiredStar"},[_vm._v("*")]),_vm._v(_vm._s(item.name))]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          item.code,
          {
            validateTrigger: ['change', 'blur'],
            rules: [
              {
                required:  item.required===1,
                whitespace: true,
                message: '请选择',
              } ],
            initialValue:item.idGoProperty
          } ]),expression:"[\n          item.code,\n          {\n            validateTrigger: ['change', 'blur'],\n            rules: [\n              {\n                required:  item.required===1,\n                whitespace: true,\n                message: '请选择',\n              },\n            ],\n            initialValue:item.idGoProperty\n          },\n        ]"}],staticStyle:{"width":"250px"},on:{"change":function (e){_vm.changePropVal(e,item)}}},_vm._l((item.goProperties),function(it,index){return _c('a-select-option',{key:index,attrs:{"value":it.id}},[_vm._v(" "+_vm._s(it.name)+" ")])}),1),_c('span',{staticClass:"unit"},[_vm._v(" "+_vm._s(item.unitName))])],1):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }