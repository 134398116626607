<template>
    <div class="main">
      <!-- 步骤条 -->
      <div class="buzhou">
        <!-- <div class="buzhou-item" :class="{'active':activeIndex===1}">
          <div class="num">
            <div>1</div>
          </div>
          <div class="buzhouMess">
            <div>选择商品分类</div>
            <div>填写商品名称、选择商品属性、上传商品图片以及相关信息。</div>
          </div>
        </div> -->
        <div class="buzhou-item" @click="$emit('preStep')" :class="{'active':activeIndex===2}">
          <div class="num">
            <div>1</div>
          </div>
          <div class="buzhouMess">
            <div>填写商品基础信息</div>
            <div>填写商品名称、选择商品属性、上传商品图片以及相关信息。</div>
          </div>
        </div>
        <div class="buzhou-item" @click="$emit('nextStep')" :class="{'active':activeIndex===3}">
          <div class="num">
            <div>2</div>
          </div>
          <div class="buzhouMess">
            <div>填写商品详情</div>
            <div>描述详情信息</div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['activeIndex'],
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.buzhou{
  margin-top: 10px;
  display: flex;
  border-radius: 5px;
  overflow:hidden;
  .buzhou-item{
    cursor: pointer;
    display: flex;
    justify-content:flex-start;
    flex: 1;
    background:#efefef;
    height:88px;
    .buzhouMess{
      padding-right: 20px;
      div:first-child{
        margin-top:10px;
        font-size: 22px;
      }
      div:last-child{
        font-size: 10px;
      }
    }
    .num{
      color: #ffffff;
      flex-grow: 0;
      flex-shrink: 0;
      width:55px;
      height:45px;
      div{
        margin-top:17px;
        margin-left: 20px;
        width:20px;
        height: 20px;
        border-radius: 50%;
        background:#74797d;
        display:flex;
        justify-content: center;
      }
    }
  }
  .active{
    background:#108fd2;
    color: #ffffff;
  }
}
</style>
