<template>
  <div>
    <a-modal
    width="900px"
    v-model="goodSpecificationsVisible"
     title="添加商品规格" @ok="handleOk">
      <div>
            <a-radio-group v-model="xuanguige">
              <a-radio-button value="1">
                选择规格
              </a-radio-button>
              <a-radio-button value="2">
                自定义规格
              </a-radio-button>
            </a-radio-group>
      </div>
      <!-- 选择列表 -->
          <a-table
          v-if="xuanguige==='1'"
          :rowKey="(record, index) => record.id"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :columns="columns"
          :data-source="specListAtGoosClass">
            <span slot="specListItemsName" slot-scope="text">
              <span v-for="(item,index) in text" :key="index">{{item.name}}{{index===text.length-1?'':'，'}}</span>
            </span>
            <span slot="action" slot-scope="text,record">
              <a @click="delGoySpec(record)" v-if="record.tenantId!=='0'">删除</a>
            </span>
          </a-table>
          <!-- 自定义规格 -->
          <a-form-model
            v-if="xuanguige==='2'"
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <br/>
            <a-form-model-item ref="name" label="规格名称" prop="name">
              <a-input v-model="form.name" placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item ref="name" label="规格值" prop="goSpecifications">
              <a-select v-model="goSpecifications" mode="tags" style="width: 100%" placeholder="请填写规格值" @change="handleChange">
              </a-select>
            </a-form-model-item>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
          </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      goyGoodsId: '',
      goSpecifications: [],
      specListAtGoosClass: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        goyId: this.goyGoodsId,
        name: '',
        goSpecifications: []
      },
      xuanguige: '1',
      goodSpecificationsVisible: false,
      saving: false,
      rules: {
        name: [
          { required: true, message: '请填写规格名', trigger: 'blur' }
        ],
        goSpecifications: [
          { required: true, message: '请填写规格值', trigger: 'blur' }
        ]
      },
      columns: [
        {
          title: '规格',
          dataIndex: 'name',
          key: 'name',
          width: 150
        },
        {
          title: '规格值',
          dataIndex: 'item',
          key: 'item',
          scopedSlots: { customRender: 'specListItemsName' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: []
    }
  },
  created () {
    // this.goyPropertyList()
  },
  watch: {
    goSpecifications (val) {
      this.form.goSpecifications = val.map((obj) => {
        return { name: obj }
      })
    }
  },
  methods: {
    delGoySpec (item) {
      console.log(item.id)
      // this.$store.dispatch('')
      this.$store.dispatch('showConfirm', {
        message: '您确定要删除吗？',
        yes: async _ => {
          const res = await this.$store.dispatch('http', {
            api: 'goySpecificationDelete',
            query: {
              id: item.id
            }
          })
          console.log(res)
          if (res) {
            this.$store.dispatch('showToast', {
              type: 'success',
              message: '删除成功'
            })
            this.getSpecList()
            // 刷新dom
          }
        }
      })
    },
    handleChange (value) {
      console.log(`selected ${value}`)
    },
    onSubmit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.ruleForm.resetFields()
    },
    handleOk () {
      console.log(this.xuanguige)
      if (this.xuanguige === '1') { // 为1是选择值
        let arr = []
        this.specListAtGoosClass.forEach((item, index) => {
          this.selectedRowKeys.forEach((item2) => {
            if (item2 === item.id) {
              arr.push(item)
            }
          })
        })
        this.$emit('change', arr)
        this.goodSpecificationsVisible = false
      } else if (this.xuanguige === '2') { // 2 是新添加值
        this.validForm()
      }
    },
    async validForm () {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.saving = true
          const params = this.$utils.clone(this.form)
          await this.$store.dispatch('http', {
            api: 'addSpec',
            params,
            complete: () => {
              this.saving = false
            }
          })
          this.$store.dispatch('showToast', { message: '操作成功' })
          this.form.goSpecifications = []
          this.form.name = ''
          this.goSpecifications = []
          this.xuanguige = '1'
          // 然后要刷新列表
          this.getSpecList()
        }
      })
    },
    async getSpecList (callback = () => { }) {
      const res = await this.$store.dispatch('http', {
        api: 'goySpecValueVueGoyGoodsId',
        query: {
          id: this.goyGoodsId
        },
        complete: () => {
          callback()
        }
      })
      this.specListAtGoosClass = res
      console.log(this.specListAtGoosClass, '商品分类下的规格列表')
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>

<style>

</style>
