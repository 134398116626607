<template>
  <div>
    <a-modal
    width="900px"
    @ok="onSubmit"
    @cancel="onCancel"
    v-model="goodSpecificationsVisible"
     :title="title">
          <!-- 自定义规格 -->
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <br/>
            <a-form-model-item ref="name" label="值" prop="name">
              <a-select v-if="actionUrls === 'spec'" v-model="form.goSpecificationStr" mode="tags" style="width: 100%" :placeholder="plachoderVal" @change="handleChange">

              </a-select>
              <!-- <a-select v-if="actionUrls === 'prp'" v-model="goProperties" mode="tags" style="width: 100%" :placeholder="plachoderVal" @change="handleChange">

              </a-select>
              <a-select v-if="actionUrls === 'spec0'" v-model="form.customizeName" mode="tags" style="width: 100%" :placeholder="plachoderVal" @change="handleChange">

              </a-select>
              <a-select v-if="actionUrls === 'prp0'" v-model="form.customizeName" mode="tags" style="width: 100%" :placeholder="plachoderVal" @change="handleChange">

              </a-select> -->

            </a-form-model-item>
            <br/>
              <br/>
              <br/>
              <br/>
          </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      goProperties: [],
      goSpecifications: [],
      actionUrls: '',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        id: '',
        name: '',
        goSpecifications: [],
        goProperties: [],
        customizeName: [],
        goyPropertyId: '',
        goySpecId: ''
      },
      goodSpecificationsVisible: false,
      rules: {
        name: [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    plachoderVal () {
      return this.actionUrls === 'spec' ? '请输入规格值，回车输入多个' : this.actionUrls === 'prp' ? '请输入属性值，回车输入多个' : ''
    },
    title () {
      return this.actionUrls === 'spec' || this.actionUrls === 'spec0' ? '添加规格值' : this.actionUrls === 'prp' || this.actionUrls === 'prp0' ? '添加属性值' : ''
    },
    apiUrl () {
      return this.actionUrls === 'spec' ? 'updateSpec'
        : this.actionUrls === 'prp' ? 'goyPropertyUpdate'
          : this.actionUrls === 'spec0' ? 'goySpecificationcustomizeSpec'
            : this.actionUrls === 'prp0' ? 'goyPropertycustomizeProperty' : ''
    }
  },
  created () {
  },
  watch: {
    goSpecifications (val) {
      this.form.goSpecifications = val.map((obj) => {
        return { name: obj }
      })
    },
    goProperties (val) {
      this.form.goProperties = val.map((obj) => {
        return { name: obj }
      })
    }
  },
  methods: {
    init (item) {
      this.actionUrls = item.actionUrls
      this.form = {}
      this.form = { ...item }
      this.form.goSpecificationStr = this.form.goSpecificationStr.split(',')
      this.goodSpecificationsVisible = true
      console.log(item)
    },
    handleChange (value) {
      // console.log(`selected ${value}`)
    },
    resetForm () {
      this.goProperties = []
      this.goSpecifications = []
      this.form = {}
    },
    onCancel () {
      this.form = {}
      this.resetForm()
    },
    onSubmit () {
      if (this.actionUrls === 'spec') {
        if (this.form.goSpecificationStr && this.form.goSpecificationStr.length === 0) {
          this.$store.dispatch('showToast', { type: 'error', message: '值不能为空' })
        } else {
          // 先删除
          let { goSpecificationStr, item } = { ...this.form }
          item.forEach((element, index) => {
            if (!goSpecificationStr.includes(element.name)) {
              item.splice(index, 1)
            }
          })
          let arr2 = []
          item.forEach((element) => {
            arr2.push(element.name)
          })

          let addedArr = []
          goSpecificationStr.forEach((element) => {
            if (!arr2.includes(element)) {
              addedArr.push({
                name: element
              })
            }
          })

          this.form.item = item.concat(addedArr)
          this.form.goSpecifications = this.form.item
          console.log(this.form)
          this.save()
        }
        // 验证通过
      } else if (this.actionUrls === 'prp') {
        if (this.form.goProperties && this.form.goProperties.length === 0) {
          this.$store.dispatch('showToast', { type: 'error', message: '值不能为空' })
        } else {
          this.save()
        }
      } else {
        if (this.form.customizeName && this.form.customizeName.length === 0) {
          this.$store.dispatch('showToast', { type: 'error', message: '值不能为空' })
        } else {
          this.save()
        }
      }

      // this.$refs.ruleForm.validate(valid => {
      //   if (valid) {
      //     this.save()
      //   } else {
      //     console.log('error submit!!')
      //     return false
      //   }
      // })
    },
    async save () {
      const res = await this.$store.dispatch('http', {
        api: this.apiUrl,
        params: this.form
      })
      console.log(res)
      if (res) {
        this.$store.dispatch('showToast', {
          type: 'success',
          message: '操作成功'
        })
        this.goodSpecificationsVisible = false
        this.$emit('needReflash', { id: this.form.id, sing: this.actionUrls })
      }
      this.goodSpecificationsVisible = false
      this.$emit('needReflash', { id: this.form.id, sing: this.actionUrls })
      this.resetForm()
    }
  }
}
</script>

<style>

</style>
