<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-11-22 10:13:06
 * @LastEditors: 高涛
 * @LastEditTime: 2021-12-29 10:45:02
-->
<template>
      <div>
        <a-form layout="inline" :labelCol="{'style':'width:130px;'}" :form="form">
          <div
            style="width:430px; display:inline-block;"
             v-for="item in propertyAtGoyGoodsList" :key="item.id"
            :required="false"
          >
            <a-form-item
              v-if="item.inputType === 'string'"
              style="width:430px;"
              :required="false"
            >
              <span slot="label"><span v-show="item.required===1" class="requiredStar">*</span>{{item.name}}</span>
              <a-input
                v-if="item.inputType === 'string'"
                @change="(e)=>{changePropVal(e,item)}"
                v-decorator="[
                  item.code,
                  {
                    validateTrigger: ['change', 'blur'],
                    rules: [
                      {
                        required: item.required===1,
                        whitespace: true,
                        message: '请输入',
                      },
                    ],
                    initialValue:item.value
                  },
                ]"
                placeholder="请输入"
                style="width: 250px;"
              />
              <span class="unit">&emsp;{{item.unitName}}</span>
            </a-form-item>

             <a-form-item
              v-if="item.inputType === 'number'"
              style="width:430px;"
              :required="false"
            >
              <span slot="label"><span v-show="item.required===1" class="requiredStar">*</span>{{item.name}}</span>

              <a-input
              @change="(e)=>{changePropVal(e,item)}" placeholder="请输入"
                v-decorator="[
                  item.code,
                  {
                    validateTrigger: ['change', 'blur'],
                    rules: [
                      {
                        required: item.required===1,
                        whitespace: true,
                        message: '请输入',
                      },
                    ],
                    initialValue:item.value
                  },
                ]"
                style="width: 250px; margin-right: 8px"
              />

              <span class="unit">&emsp;{{item.unitName}}</span>
            </a-form-item>

             <a-form-item
                v-if="item.inputType === 'boolean'"
                style="width:430px;"
                :required="false"
              >
              <span slot="label"><span v-show="item.required===1" class="requiredStar">*</span>{{item.name}}</span>
              <a-radio-group
                v-decorator="[
                  item.code,
                  {
                    validateTrigger: ['change', 'blur'],
                    rules: [
                      {
                        required: item.required===1,
                        whitespace: true,
                        message: '请选择',
                      },
                    ],
                    initialValue:item.value
                  },
                  { initialValue:'1' }
                ]"
               @change="(e)=>{changePropVal(e,item)}">
                <a-radio value="1">
                  是
                </a-radio>
                <a-radio value="0">
                  否
                </a-radio>
              </a-radio-group>
              <span class="unit">&emsp;{{item.unitName}}</span>
            </a-form-item>

             <a-form-item
              v-if="item.inputType === 'date'"
              style="width:430px;"
              :required="false"
            >
              <span slot="label"><span v-show="item.required===1" class="requiredStar">*</span>{{item.name}}</span>

              <a-date-picker
                v-decorator="[
                  item.code,
                  {
                    validateTrigger: ['change', 'blur'],
                    rules: [
                      {
                        required:  item.required===1,
                        whitespace: true,
                        message: '请选择日期',
                      },
                    ],
                    initialValue:item.value
                  },
                ]"
              style="width:250px;" valueFormat="YYYY-MM-DD 00:00:00" format="YYYY-MM-DD 00:00:00" @change="(e)=>{changePropVal(e,item)}" />
              <span class="unit">&emsp;{{item.unitName}}</span>
            </a-form-item>

             <a-form-item
              v-if="item.inputType === 'radio'"
              style="width:430px;"
              :required="false"
            >
              <span slot="label"><span v-show="item.required===1" class="requiredStar">*</span>{{item.name}}</span>
              <a-select style="width:250px;"
              v-decorator="[
                item.code,
                {
                  validateTrigger: ['change', 'blur'],
                  rules: [
                    {
                      required:  item.required===1,
                      whitespace: true,
                      message: '请选择',
                    },
                  ],
                  initialValue:item.idGoProperty
                },
              ]"
              @change="(e)=>  {changePropVal(e,item)}">
                <a-select-option v-for="(it,index) in item.goProperties" :key="index" :value="it.id">
                  {{it.name}}
                </a-select-option>
              </a-select>
              <span class="unit">&emsp;{{item.unitName}}</span>
            </a-form-item>
          </div>
        </a-form>
      </div>
</template>

<script>
export default {
  data () {
    return {
      colonBool: false,
      propertyAtGoyGoodsList: [], // 某个商品 特有的属性组 属性值
      goodsProperties: [], // 回显的数据
      goPropertyDTOS: [], // 传给form的数据
      form: this.$form.createForm(this)
    }
  },
  methods: {
    validate () {
      let passValidate = false
      this.form.validateFields((err, values) => {
        console.log(err, values, '值')
        if (!err) {
          passValidate = true
        }
      })
      return passValidate
    },

    async init (obj) {
      const { idGoyGoods, goodsProperties } = obj
      console.log(idGoyGoods, goodsProperties, '初始化')

      // 根据传入的分类idGoyGoods   查询该分类下 匹配的属性
      if (idGoyGoods) {
        this.propertyAtGoyGoodsList = await this.propertyAtGoyGoods(idGoyGoods)
      }
      // 如果传入了 goodsProperties 说明是  回显信息
      if (goodsProperties) {
        this.goodsProperties = goodsProperties
        this.setValue(this.propertyAtGoyGoodsList, this.goodsProperties)
      } else {
        this.setValue(this.propertyAtGoyGoodsList)
      }
      // 设置组装后的值
      this.setGoPropertyDTOS(idGoyGoods, goodsProperties)
      this.emitData()
    },
    setGoPropertyDTOS (idGoyGoods, goodsProperties) {
      if (!goodsProperties) {
        this.goPropertyDTOS = []
      } else {
        goodsProperties.forEach((item) => {
          this.goPropertyDTOS.push({
            idGoProperty: item.idGoProperty,
            idGoyProperty: item.idGoyProperty,
            name: item.name
          })
        })
      }
    },
    // 查询商品分类下的 属性组 属性值
    async propertyAtGoyGoods (id) {
      const res = await this.$store.dispatch('http', {
        api: 'propertyAtGoyGoods',
        query: {
          id
        }
      })
      return res
    },
    // 没有传valueList 是新增  否则是编辑
    setValue (propList, valueList) {
      if (!valueList) {
      } else {
        propList.forEach(item => {
          item.value = this.getStringVal(item.id, valueList).name
          item.idGoProperty = this.getStringVal(item.id, valueList).idGoProperty
        })
      }
    },
    getStringVal (id, valueList) {
      let item2 = {}
      valueList.forEach(item => {
        if (item.idGoyProperty === id) {
          item2 = item
        }
      })
      return item2
    },
    changePropVal (e, item) {
      console.log(e, item, this.$form, '改变值哦')
      if (['string', 'number', 'boolean'].includes(item.inputType)) item.value = e.target.value
      if (['date'].includes(item.inputType)) item.value = e
      if (['radio'].includes(item.inputType)) item.idGoProperty = e
      this.emitData()
    },
    emitData () {
      this.goPropertyDTOS = []
      for (const { id, value, idGoProperty, required, inputType } of this.propertyAtGoyGoodsList) {
        console.log(id, value, idGoProperty, required, inputType, 'aaaaa')
        this.goPropertyDTOS.push({
          name: inputType === 'radio' ? undefined : value,
          idGoProperty: idGoProperty,
          idGoyProperty: id,
          required: required,
          inputType: inputType
        })
      }
      this.$emit('change', this.goPropertyDTOS)
    }
  }
}
</script>

<style lang="scss" scoped>
.propertyItem{
  width:49.9%;
  display:inline-block;
  height:30px;
  line-height:30px;
  >div{
    display: flex;
    >div.left{
      text-align:right;
      width:100px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;

    }
    div.center{
      flex:1;
    }
    div.unit{
      width:40px;
      padding:0 10px;
    }
  }
}
.requiredStar{
  color: red;
}
</style>
